import { endpoints } from '@/js/endpoints';
import { cloneDeep, orderBy as orderByFunc } from 'lodash';

export const givenFeedbackPageLimit = 20;

const feedbackState = {
  queueId: null,
  queue: [],

  // current feedback page
  myFeedback: null,
  myFeedbackCount: null,
  myFeedbackPage: 1,

  allFeedback: null,
  allFeedbackCount: null,
  allFeedbackPage: 1,

  mineMode: true,

  feedbackAdmin: {
    users: [],
    groups: [],
    rootTables: [],
    filterFieldsOptions: {},
    labelFieldsOptions: {},
  },

  alignedFeedbackAdmin: {
    users: [],
    groups: [],
    fields: [],
  },

  alignedFeedback: {
    feedback: [],
    count: null,
    page: 1,
  },

};

const feedbackGetters = {
  mineMode: (state) => state.mineMode,

  currentQueueId: (state) => state.queueId,
  currentQueue: (state) => state.queue,

  currentFeedback: (state) => state.alignedFeedback.feedback,
  feedbackPage: (state) => state.alignedFeedback.page,
  feedbackEntryCount: (state) => state.alignedFeedback.count,
  // return [1, 1 + Math.floor(count / givenFeedbackPageLimit)]
  feedbackAdminUsers: (state) => state.alignedFeedbackAdmin.users,
  feedbackAdminGroups: (state) => state.alignedFeedbackAdmin.groups,
  feedbackAdminFields: (state) => state.alignedFeedbackAdmin.fields,

  feedbackAdminRootFilterFields: (state) => state.feedbackAdmin.filterFieldsOptions,

};

const mutations = {
  setQueueId(state, { queueId }) {
    state.queueId = queueId;
  },
  clearQueue(state) {
    state.queue = [];
  },
  addToQueue(state, item) {
    state.queue.push(item);
  },
  removeFromQueue(state, { id }) {
    const index = state.queue.findIndex((e) => e.id === id);
    state.queue.splice(index, 1);
  },

  setSelected(state, {
    id, fieldKey, value, type,
  }) {
    let entry = {};
    if (type === 'display_feedback') {
      entry = state.alignedFeedback.feedback.find((e) => e.id === id);
    } else {
      entry = state.queue.find((e) => e.id === id);
    }
    const field = entry.fields.find((e) => e.key === fieldKey);
    field.selected = value;
  },
  setVerified(state, {
    id, fieldKey, value, type,
  }) {
    let entry = {};
    if (type === 'display_feedback') {
      entry = state.alignedFeedback.feedback.find((e) => e.id === id);
    } else {
      entry = state.queue.find((e) => e.id === id);
    }
    const field = entry.fields.find((e) => e.key === fieldKey);
    field.verified = value;
  },

  removeFeedback(state, { entryBatch, fieldUnid }) {
    const list = state.alignedFeedback.feedback;
    const entry = list.find((e) => e.batch === entryBatch);
    const fieldIndex = entry.fields.findIndex((e) => e.unid === fieldUnid);
    if (fieldIndex !== -1) {
      entry.fields.splice(fieldIndex, 1);
    }
    if (entry.fields.length === 0) {
      const entryIndex = list.findIndex((e) => e.batch === entryBatch);
      list.splice(entryIndex, 1);
    }
  },
  setFeedbackSelected(state, { id, value }) {
    const list = state.alignedFeedback.feedback;
    const entry = list.find((e) => e.id === id);
    entry.label.selected = value;
  },
  setFeedbackInfo(state, { pageNumber, count }) {
    if (Number.isInteger(pageNumber)) {
      state.alignedFeedback.page = pageNumber;
    }
    if (Number.isInteger(count)) {
      state.alignedFeedback.count = count;
    }
  },
  resetMyFeedback(state) {
    state.alignedFeedback.feedback = [];
  },
  addMyFeedback(state, content) {
    if (!content) return;
    state.alignedFeedback.feedback.push(content);
  },

  updateSingleFeedback(state, { content, entryBatch }) {
    if (content !== undefined) {
      const target = state.alignedFeedback.feedback.find((entry) => entry.batch === entryBatch);
      if (target) {
        const field = target.fields.find((f) => f.key === content.tag.field);
        const newSelected = content.tag.id;
        if (field) {
          field.selected = newSelected;
          field.date_modified = content.date_modified;
          field.verified = false;
        } else {
          console.log(`Found entry but could not find field ${content}`);
        }
      } else {
        console.log(`could not find entry ${entryBatch}`);
      }
    }
  },
  setAlignedFeedbackAdminOptions(state, {
    users, groups, fields,
  }) {
    state.alignedFeedbackAdmin.users = users;
    state.alignedFeedbackAdmin.groups = groups;
    state.alignedFeedbackAdmin.fields = fields;
  },
};

const actions = {
  async refreshQueue({ commit, state, dispatch }, {
    filterTags = [], excludeTags = [], orderBy = 'time',
    anomaliesOnly = true, handledOnly = true,
    nEntries = 100,
  }) {
    commit('clearQueue');
    if (!state.queueId) return;
    const params = {
      order_by: orderBy,
      only_anomalies: anomaliesOnly,
      n_entries: nEntries,
      handled_only: handledOnly,
    };
    if (filterTags.length > 0) {
      params.filter_tags = filterTags;
    }
    if (excludeTags.length > 0) {
      params.exclude_tags = excludeTags;
    }

    const [
      { fields, entries },
      tags,
    ] = await Promise.all(
      [
        dispatch('auth/callBackendSuccess', {
          url: `${endpoints.myFeedbackQueue + state.queueId}/`,
          params,
        }, { root: true }),
        dispatch('ensureTags', {}, { root: true }),
      ],
    );

    // Order by name
    const newFields = orderByFunc(fields, ['name'], ['asc']);

    // this stuff is pretty heavy
    for await (const entry of entries) {
      const fieldId2tag = entry.tags.reduce((o, tagId) => {
        const tag = tags.pk2tag[tagId];
        if (tag != null) {
          return {
            ...o,
            [tag.field_id]: {
              id: tagId,
              value: tag.value,
              display: tag.display,
              field: {
                id: tag.field_id,
                name: tags.fieldId2field[tag.field_id].name,
              },
            },
          };
        }
        return o;
      }, {});
      // Field id maps to most recent anomaly
      const field2anomaly = orderByFunc(entry.anomalies, ['found_at'], ['desc'])
        .reduce((o, anomaly) => ({
          ...o,
          [anomaly.field_config.field]: anomaly,
        }), {});
      commit('addToQueue', {
        id: entry.unid,
        content: cloneDeep(entry),
        fields: newFields.map((x) => {
          const t = fieldId2tag[x.id];
          return {
            field: x,
            tag: t,
            key: x.id,
            selected: t !== undefined ? t.id : null,
            verified: t !== undefined,
            anomaly: field2anomaly[x.id],
          };
        }),
      });
      // let the frontend update
      await new Promise((res) => { setTimeout(() => { res(); }, 25); });
    }
  },

  ensureQueue({ state, dispatch }) {
    if (state.queue.length === 0) {
      return dispatch('refreshQueue');
    }
    return new Promise(((resolve) => {
      resolve(state.queue);
    }));
  },

  async alignedRefreshFeedback({ commit, state, dispatch }, { pageNumber, groupByBatch }) {
    // Always refresh this pageNumber and setFeedbackInfo

    let url = state.mineMode ? endpoints.myFeedback : endpoints.feedbackAdmin;
    url += `?page=${pageNumber}`;
    if (groupByBatch) url += `&group_by_batch=${groupByBatch}`;
    url += `&page_size=${givenFeedbackPageLimit}`;
    const [{ results, count }, tags] = await Promise.all([
      dispatch('auth/callBackendSuccess', { url }, { root: true }),
      dispatch('ensureTags', {}, { root: true }),
    ]);
    commit('resetMyFeedback');
    commit('setFeedbackInfo', {
      pageNumber, count,
    });

    // Process and prepare the payload
    for (const batch of results) {
      // Field id maps to most recent anomaly
      const field2anomaly = batch.ticket.anomalies.sort(
        (e) => (e.found_at),
      ).reduce(
        (o, anomaly) => ({
          ...o,
          [anomaly.field_config.field]: anomaly,
        }),
        {},
      );
      commit('addMyFeedback', {
        id: batch.ticket.unid,
        batch: batch.batch,
        content: JSON.parse(JSON.stringify(batch.ticket)),
        fields: batch.entries.map((x) => ({
          unid: x.unid,
          field: tags.fieldId2field[x.tag.field],
          tag: x.tag,
          key: x.tag.field,
          selected: x.tag.id,
          verified: false,
          date_modified: x.date_modified,
          anomaly: field2anomaly[x.tag.field],
        })),
      });
      // outcommented as it doesnt behave well with the pagination
      // but if needed, then change the for loop to a for await loop
      // let the frontend update
      // await new Promise((res) => { setTimeout(() => { res(); }, 25); });
    }
  },

  ensureFeedback({ dispatch, getters }, { pageNumber, groupByBatch, refresh = true }) {
    if (getters.feedbackPage === pageNumber && getters.currentFeedback.length && !refresh) {
      return new Promise((resolve) => {
        resolve(getters.currentFeedback);
      });
    }
    return dispatch('alignedRefreshFeedback', { pageNumber, groupByBatch });
  },

  async getAlignedFeedbackAdminOptions({ dispatch, commit }) {
    const {
      users, groups, fields,
    } = await dispatch('auth/callBackendSuccess', {
      url: endpoints.feedbackAdminQueueOptions,
    }, { root: true });
    commit('setAlignedFeedbackAdminOptions', {
      users,
      groups,
      fields,
    });
    return {
      users,
      groups,
      fields,
    };
  },
  async ensureFeedbackAdminOptions({ dispatch, state }) {
    if (!state.feedbackAdmin.users.length) {
      return dispatch('getAlignedFeedbackAdminOptions');
    }
    const {
      users, groups, fields,
    } = state.alignedFeedbackAdmin;
    return {
      users,
      groups,
      fields,
    };
  },
};

export default {
  namespaced: true,
  state: feedbackState,
  getters: feedbackGetters,
  mutations,
  actions,
};
