import { endpoints } from '@/js/endpoints';

function doesExist(key) {
  if (typeof key === 'object') {
    return Object.keys(key).length > 0;
  }
  return key.length > 0;
}

const anomaliesState = {
  groupOptions: [],
  group2EntryOptions: {},
  group2entry2display: {},
};

const getters = {
  groupOptions: (state) => state.groupOptions,
  group2EntryOptions: (state) => state.group2EntryOptions,
  group2entry2display: (state) => state.group2entry2display,
};

const mutations = {
  setGroupOptions(state, options) {
    state.groupOptions = [
      { text: 'Select Anomaly Group', value: null, disabled: true },
    ].concat(options.map((x) => ({ text: x.name, value: x.unid })));
    for (const grp of options) {
      state.group2EntryOptions[grp.unid] = grp.options
        .map((x) => ({ text: x.display, value: x.unid }));
      const mapping = {};
      for (const ent of grp.options) {
        mapping[ent.unid] = ent.display;
      }
      state.group2entry2display[grp.unid] = mapping;
    }
    return state;
  },
};

const actions = {
  async ensureGroupOptions({ commit, dispatch, state }) {
    // Check if we already have the data we need.
    if ([
      doesExist(state.groupOptions),
      doesExist(state.group2EntryOptions),
      doesExist(state.group2entry2display),
    ].every((wasFound) => (wasFound))) return;
    // If not, let's get it!
    const { results } = await dispatch(
      'auth/callBackendSuccess',
      { url: `${endpoints.anomalyGroup}` },
      { root: true },
    );
    commit('setGroupOptions', results);
  },
};

export default {
  namespaced: true,
  state: anomaliesState,
  getters,
  mutations,
  actions,
};
