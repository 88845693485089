import { createStore } from 'vuex';

import templateStore from 'supwiz/components-vue3/template/templateStore';
import { endpoints } from '@/js/endpoints';
// eslint-disable-next-line import/no-cycle
import auth from './auth';
import entity from './modules/entity';
import feedback from './modules/feedback';
// eslint-disable-next-line import/no-cycle
import models from './modules/models';
import sidebar from './modules/sidebar';
// eslint-disable-next-line import/no-cycle
import customrules from './modules/customrules';
import anonymization from './modules/anonymization';
import anomalies from './modules/anomalies';
import access from './modules/access';
// eslint-disable-next-line import/no-cycle
import routing from './modules/routing';
// eslint-disable-next-line import/no-cycle
import swaps from './modules/swaps';
// eslint-disable-next-line import/no-cycle
import ai from './modules/ai';
import tasks from './modules/tasks';
import statistics from './modules/statistics';

// Template based modules
import momaModule from './modules/momaModule';
import trainedModel from './modules/trainedModel';

const incrementPlugin = (store) => {
  // check if token should be refreshed
  setInterval(() => {
    store.dispatch('auth/inspectToken');
  }, 120000);
  // fetch task and task-queue status
  setInterval(async () => {
    if (store.state.auth.loggedIn) {
      store.dispatch('tasks/refresh');
    }
  }, 10000);
};

function reindexTags(tags) {
  // input format: {pk: {value, field}}
  // output format: {field: {value: pk}} and {field-id: [{pk, value}]}
  const out = {
    byId: {},
    IdToValue: {},
    byName: {},
    fieldId2field: {},
  };
  for (const [pk, dict] of Object.entries(tags)) {
    if (dict.field != null) {
      if (!(dict.field in out.byName)) {
        out.byName[dict.field] = {};
      }
      out.byName[dict.field][dict.display] = pk;
    }
    if (dict.field_id != null) {
      if (!(dict.field_id in out.byId)) {
        out.byId[dict.field_id] = [];
      }
      if (!(dict.field_id in out.IdToValue)) {
        out.IdToValue[dict.field_id] = [];
      }
      out.byId[dict.field_id].push({ value: pk, text: dict.display, display: dict.tag_display });
      out.fieldId2field[dict.field_id] = { name: dict.field, id: dict.field_id };
      out.IdToValue[dict.field_id].push({ value: dict.value, text: dict.display });
    }
  }
  for (const fieldId of Object.keys(out.byId)) {
    out.byId[fieldId] = out.byId[fieldId].sort(
      // eslint-disable-next-line no-nested-ternary
      (a, b) => ((a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0)),
    );
  }
  return out;
}

const colorList = [
  'mediumpurple', 'darkcyan', 'darkblue',
  'darkslategrey', 'darkorange', 'darkseagreen', 'darkturquoise',
  'tan', 'rosybrown', 'cadetblue', 'peru',
  'rebeccapurple', 'teal',
];

export default createStore({
  state: {
    mountInfo: null,
    tags: null,
    connectionConfig: null,
  },
  getters: {
    hasMountInfo(state) {
      return state.mountInfo !== null;
    },
    hasTags(state) {
      return state.tags !== null;
    },
    itsmSystem(state) {
      return state.mountInfo.itsm_system;
    },
    itsmSystemCode(state) {
      return state.mountInfo.itsm_system_code;
    },
    userName(state) {
      return state.mountInfo.username;
      // if (state.mountInfo !== null) {
      //   return state.mountInfo.username || 'MissingName';
      // }
      // return 'MissingName';
    },
    role(state) {
      return state.mountInfo.role;
    },
    fieldConfig2Name(state) {
      return state.mountInfo.field_config2name;
    },
    tablePK: (state) => (key) => state.mountInfo.table_pks[key],
    ticketNumberFormats(state) {
      return {
        regular: state.mountInfo.ticket_number_format,
        system: state.mountInfo.sys_id_format,
      };
    },
    tags(state) {
      return state.tags;
    },
    externalUrlPattern(state) {
      return state.mountInfo.external_url_pattern;
    },
    missingFields(state) {
      return state.mountInfo.missing_config_fields;
    },
    connectionConfig(state) {
      return state.connectionConfig;
    },
  },
  mutations: {
    setMountInfo(state, payload) {
      state.mountInfo = payload;
    },
    setTags(state, newTags) {
      const field2tagWithId = reindexTags(newTags);
      const field2tag = field2tagWithId.byName;
      const field2color = {};
      const fieldId2field = field2tagWithId.fieldId2field;
      let i = 0;
      for (const fieldName of Object.keys(field2tag).sort()) {
        field2color[fieldName] = colorList[i % colorList.length];
        i += 1;
      }
      const flatList = [];
      for (const fieldName of Object.keys(field2tag).sort()) {
        for (const value of Object.keys(field2tag[fieldName]).sort()) {
          flatList.push({
            ...newTags[field2tag[fieldName][value]],
            id: field2tag[fieldName][value],
          });
        }
      }
      state.tags = {
        pk2tag: newTags,
        field2options: field2tagWithId.byId,
        field2valueOptions: field2tagWithId.IdToValue,
        flatList,
        field2tag,
        field2color,
        fieldId2field,
      };
    },
    setConnectionConfig(state, payload) {
      state.connectionConfig = payload;
    },
  },
  actions: {
    async authentication({
      state, commit, dispatch,
    }) {
      if (state.auth.jwt) {
        try {
          await dispatch('auth/inspectToken');
          await dispatch('auth/verifyToken');
          await dispatch('auth/getPermissions');
          await dispatch('ensureMountInfo');
          await dispatch('ensureTags');
          commit('auth/updateLoggedIn', true);
          commit('sidebar/setShowSidebar', true);
          return 'success';
        } catch (e) {
          throw new Error(e);
        }
      }
      return 'no token';
    },
    async ensureMountInfo({ state, commit, dispatch }) {
      if (state.mountInfo === null) {
        const info = await dispatch('auth/callBackendSuccess', {
          url: endpoints.mountInfo,
        });
        commit('setMountInfo', info);
        commit('templateStore/templateSetUserDetails', { displayName: info.username }, { root: true });
      }
    },
    async refreshMountInfo({ commit, dispatch }) {
      const info = await dispatch('auth/callBackendSuccess', {
        url: endpoints.mountInfo,
      });
      commit('setMountInfo', info);
    },
    async ensureTags({ state, commit, dispatch }) {
      if (state.tags === null) {
        const tags = await dispatch('auth/callBackendSuccess', {
          url: endpoints.tagOptions,
        });
        commit('setTags', tags.pk2tag);
      }
      return state.tags;
    },
    async ensureConnectionConfig({ state, commit, dispatch }, { refreshing }) {
      if (state.connectionConfig === null || refreshing) {
        const connectionConfig = await dispatch('auth/callBackendSuccess', {
          url: endpoints.connectionConfig,
        });
        commit('setConnectionConfig', connectionConfig.results[0]);
      }
    },
  },
  modules: {
    auth,
    sidebar,
    entity,
    feedback,
    models,
    customrules,
    anonymization,
    anomalies,
    access,
    routing,
    templateStore,
    swaps,
    ai,
    tasks,
    statistics,
    momaModule,
    trainedModel,
  },
  plugins: [incrementPlugin],
});
