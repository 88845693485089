import { endpoints } from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';
import GeneralTemplate from '@/js/vuexTemplates/general';

const momaModuleState = {};

const momaModuleGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.module, 'momaModule'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: momaModuleState, getters: momaModuleGetters, mutations, actions,
  }, templates),
};
