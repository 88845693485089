import { endpoints } from '@/js/endpoints';
// eslint-disable-next-line import/no-cycle
import { collectPaginatedContent } from '@/js/apiHandlers';

export function dataCollection2FormDetails(dataCollection) {
  const out = {};
  for (const fieldConfig of dataCollection.fields) {
    if (fieldConfig.type !== 'dum' && fieldConfig.type !== 'lab') {
      out[fieldConfig.field_name] = {
        key: fieldConfig.field_name,
        ...fieldConfig,
      };
    }
  }
  return out;
}

const aiState = {
  // Where to put input of a ticket
  formDetails: null,
  ticketForm: null,

  // Data Collection unid to form details
  dataCollections: {},
  dataCollectionOptions: [],

  // response
  rawOutput: null,
  output: null,
  asking: false,

  // // Output response
  // response: null,
  // responseOK: null,
  // aiResponse: null,
  // aiResponseOK: null,

  // Test Site data
  swapUnitsData: [],
};

const aiGetters = {
  ticketForm: (state) => state.ticketForm,
  formDetails: (state) => state.formDetails,
  asking: (state) => state.asking,
  output: (state) => state.output,
  rawOutput: (state) => state.rawOutput,
  outputOK: (state) => state.output != null && state.output.error == null,
  rawOutputOK: (state) => state.rawOutput != null && state.rawOutput.error == null,
  dataCollectionOptions(state) {
    return Object.entries(state.dataCollections).map(
      ([key, value]) => ({
        value: key, text: value.name,
      }),
    );
  },
  dataCollection: (state) => (dcUnid) => state.dataCollections[dcUnid],
  cleanTicketInput(state) {
    const toOut = {};
    for (const [key, config] of Object.entries(state.formDetails)) {
      if (['txt', 'clb', 'cat'].includes(config.type)) {
        toOut[key] = state.ticketForm[key] == null ? '' : state.ticketForm[key];
      }
    }
    return toOut;
  },
  swapUnitsData: (state) => state.swapUnitsData,
  // response: (state) => state.response,
  // responseOK: (state) => state.responseOK,
  // aiResponse: (state) => state.aiResponse,
  // aiResponseOK: (state) => state.aiResponseOK,
};

const mutations = {
  setTicketForm(state, dcUnid) {
    const dc = state.dataCollections[dcUnid];
    if (dc != null) {
      state.formDetails = dataCollection2FormDetails(dc);
      this.commit('ai/clearTicketForm');
    }
  },
  clearTicketForm(state) {
    const newForm = {};
    for (const [key, fieldConfig] of Object.entries(state.formDetails)) {
      newForm[key] = fieldConfig.type === 'txt' ? '' : null;
    }
    state.ticketForm = newForm;
  },
  setDataCollections(state, dataCollections) {
    state.dataCollections = dataCollections;
  },
  setAsking(state, asking) {
    state.asking = asking;
  },
  setOutput(state, output) {
    state.output = output;
  },
  setRawOutput(state, rawOutput) {
    state.rawOutput = rawOutput;
  },
  mergeRawOutput(state, rawOutput) {
    if (state.rawOutput != null) {
      state.rawOutput = { ...state.rawOutput, ...rawOutput };
    } else {
      state.rawOutput = rawOutput;
    }
  },
  setSwapUnits(state, data) {
    state.swapUnitsData = data;
  },

  // clearResponse(state) {
  //   state.response = null;
  //   state.responseOK = null;
  // },
  // setResponse(state, { resp }) {
  //   state.response = resp;
  // },
  // setAiResponse(state, { resp }) {
  //   state.aiResponse = resp;
  // },
  // setResponseOK(state, { ok }) {
  //   state.responseOK = ok;
  // },
  // setAiResponseOK(state, { ok }) {
  //   state.aiResponseOK = ok;
  // },
};

const actions = {
  clearAIOutputs({ commit }, { output = false, rawOutput = false }) {
    if (output) {
      commit('setOutput', null);
    }
    if (rawOutput) {
      commit('setRawOutput', null);
    }
  },
  async applyRules({
    state, getters, commit, dispatch, rootGetters,
  }, { ruleCollectionUnid, includeSuggestions }) {
    // If rawOutput missing, call first askForPredictions
    if (getters.asking) {
      return;
    }
    commit('setAsking', true);
    dispatch('customrules/setRulesApplied', { rulesApplied: null }, { root: true });
    dispatch('clearAIOutputs', { output: true, rawOutput: true });
    const rc = rootGetters['customrules/ruleCollectionContent'];
    if (!rc) {
      commit('setAsking', false);
      return;
    }
    const data = await dispatch('backendCompatibleTicketForm');
    await dispatch('getSwapUnits');
    const swapUnits = state.swapUnitsData
      .filter((x) => x.dataCollectionUnid === rc.group.data_collection_unid);
    await Promise.all(swapUnits.map(
      (swapUnit) => dispatch('askForPredictions', {
        swapName: swapUnit.name, momaModuleName: swapUnit.name, markAsking: false, data,
      }),
    ));
    // Then send to /api/custom-rules/test/rules
    if (getters.rawOutputOK) {
      const result = await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.testRules}`,
        method: 'POST',
        payload: {
          input: getters.cleanTicketInput,
          rule_collection_unid: ruleCollectionUnid,
          ai_input: getters.rawOutput,
          include_suggested_rules: includeSuggestions,
        },
      }, { root: true });
      commit('setOutput', result.output);
      dispatch('customrules/setRulesApplied', {
        rulesApplied: result.rules_applied,
      }, { root: true });
      dispatch('customrules/setSuggestionsApplied', {
        suggestionsApplied: result.suggested_rules_applied,
      }, { root: true });
    }
    commit('setAsking', false);
  },
  async askForPredictions({
    state, getters, commit, dispatch,
  }, {
    swapName, momaModuleName = null, markAsking = true, data = null,
  }) {
    let payload = data;
    if (payload === null) {
      payload = await dispatch('backendCompatibleTicketForm');
    }
    // Called in test site and for setting raw predictions
    const raw = momaModuleName != null;
    if (state.formDetails == null || state.ticketForm == null) {
      return;
    }
    if (markAsking) {
      if (getters.asking) {
        return;
      }
      commit('setAsking', true);
    }
    dispatch('clearAIOutputs', { output: !raw, rawOutput: raw });
    try {
      await dispatch('auth/inspectToken', {}, { root: true });
      const response = await dispatch('auth/callBackend', {
        url: `${endpoints.swapsAI}${swapName}/`,
        method: 'POST',
        payload: {
          meta: {},
          data: payload,
        },
        params: {
          test_call: true,
          skip_rules: raw,
          moma_module_name: momaModuleName,
        },
      }, { root: true });
      const status = response.status;
      if (status >= 200 && status < 300) {
        const result = response.data;
        const notRaw = (result.meta != null && result.response != null);
        const toMerge = notRaw ? result.response : result;
        if (notRaw) {
          for (const key of Object.keys(toMerge)) {
            toMerge[key] = toMerge[key].map((x) => ({ ...x, tag: x.tag_id }));
          }
        }
        commit(raw ? 'mergeRawOutput' : 'setOutput', toMerge);
      }
    } catch (error) {
      if (!(raw && error.response.status === 404)) {
        commit(raw ? 'setRawOutput' : 'setOutput', { error });
      }
    }
    if (markAsking) {
      commit('setAsking', false);
    }
  },
  async setTicketForm({ commit, dispatch }, { dcUnid }) {
    await dispatch('ensureDataCollections');
    commit('setTicketForm', dcUnid);
  },
  async refreshDataCollections({ commit }) {
    await collectPaginatedContent(endpoints.dataCollection, 0, null).then((result) => {
      const newCollections = {};
      for (const row of result) {
        newCollections[row.unid] = row;
      }
      commit('setDataCollections', newCollections);
    });
  },
  async ensureDataCollections({ state, dispatch }) {
    if (Object.keys(state.dataCollections).length === 0) {
      await dispatch('refreshDataCollections');
    }
    return state.dataCollections;
  },
  async backendCompatibleTicketForm({ dispatch, getters }) {
    const tags = await dispatch('ensureTags', {}, { root: true });
    const toOut = getters.cleanTicketInput;
    for (const [key, config] of Object.entries(getters.formDetails)) {
      if (['clb', 'cat'].includes(config.type)) {
        if (toOut[key] !== '' && toOut[key] != null) {
          const tag = tags.pk2tag[toOut[key]];
          if (tag != null) {
            toOut[key] = tag.value;
          } else {
            console.log(`Could not resolve tag ${toOut[key]} of key ${key}.`);
          }
        }
      }
    }
    return toOut;
  },
  async getSwapUnits({ state, dispatch, commit }) {
    if (!Object.values(state.swapUnitsData).length) {
      await dispatch('auth/callBackendSuccess', { url: endpoints.testSiteMeta }, { root: true }).then((data) => {
        const preparedData = [];
        for (const item of data.results) {
          preparedData.push({
            unid: item.unid,
            dataCollectionUnid: item.data_collection.unid,
            name: item.name,
          });
        }
        commit('setSwapUnits', preparedData);
      });
    }
    return true;
  },
};

export default {
  namespaced: true,
  state: aiState,
  getters: aiGetters,
  mutations,
  actions,
};
