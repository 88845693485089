import { sortBy } from 'lodash';

const pages = [
  {
    label: 'Home',
    to: { name: 'home' },
    icon: 'home',
  },
  {
    label: 'AI',
    icon: 'robot',
    items: [
      {
        label: 'Testing Site',
        to: { name: 'ai_test_site' },
      },
      {
        label: 'Usage',
        to: { name: 'ai_usage' },
      },
    ],
  },
  {
    label: 'Insights',
    icon: 'chart-line',
    items: [
      {
        label: 'Similarity Search',
        to: { name: 'similarity_search' },
      },
      {
        label: 'Statistics',
        to: { name: 'statistics' },
      },
    ],
  },
  {
    label: 'Feedback',
    icon: 'clipboard-list',
    items: [
      {
        label: 'Improve AI',
        to: { name: 'give_feedback' },
        tags: 'give feedback train model',
      },
      {
        label: 'My Feedback',
        to: { name: 'display_feedback' },
      },
      {
        label: 'Configure Queues',
        to: { name: 'queues_config' },
      },
      {
        label: 'Anomalies',
        to: { name: 'find_anomaly' },
      },
    ],
  },
  {
    label: 'Models',
    icon: 'brain',
    items: [
      {
        label: 'Configure',
        to: { name: 'configure' },
      },
      {
        label: 'Training',
        to: { name: 'training' },
      },
      {
        label: 'Stage',
        to: { name: 'stage' },
      },
    ],
  },
  {
    label: 'Custom Rules',
    icon: 'less-than-equal',
    matchOn: ['custom-rules', 'ruleHome', 'ruleEdit', 'ruleStage'],
    items: [
      {
        label: 'Manage',
        to: { name: 'ruleHome' },
        matchOn: ['ruleHome', 'ruleEdit'],
      },
      {
        label: 'Stage',
        to: { name: 'ruleStage' },
      },
    ],
  },
  {
    label: 'Routing',
    icon: 'exchange-alt',
    items: [
      {
        label: 'Logs',
        to: { name: 'routingLogs' },
      },
      {
        label: 'Manage',
        to: { name: 'routingManage' },
      },
      {
        label: 'Stage',
        to: { name: 'routingStage' },
      },
    ],
  },
  {
    label: 'Configuration',
    icon: 'wrench',
    items: [
      {
        label: 'Anonymization',
        to: { name: 'anonymization' },
      },
      {
        label: 'Connection',
        to: { name: 'connection_config' },
      },
      {
        label: 'Data Sync',
        to: { name: 'data_sync' },
      },
      {
        label: 'Data Purge',
        to: { name: 'data_purge' },
      },
      {
        label: 'Signature Stripping',
        to: { name: 'signature_stripping' },
      },
      {
        label: 'Documentation',
        to: { name: 'api_docs' },
      },
      {
        label: 'User Management',
        to: { name: 'user_management' },
      },
      {
        label: 'Task Status',
        to: { name: 'task_status' },
      },
      {
        label: 'Single Sign On',
        to: { name: 'single_sign_on' },
      },
    ],
  },
];
const sidebarState = {
  showSidebar: false,
  pageLoading: false,
  notifications: [],
};

function checkPermission(permissions, page) {
  return permissions[page.to.name] !== 'no';
}
const sidebarGetters = {
  branchesWithAccess: (state, _getters, _rootState, rootGetters) => {
    const permissions = rootGetters['auth/featurePermissions'];
    return pages.map((page) => {
      if (!page.items) {
        if (!checkPermission(permissions, page)) return null;
        return page;
      }
      return {
        ...page,
        items: page.items.filter((childPage) => checkPermission(permissions, childPage)),
      };
    }).filter((page) => {
      if (page !== null && !page?.items) return true;
      return page?.items?.length > 0;
    });
  },
  chosenPage: (state) => state.chosenPage,
  pages: (state, getters) => getters.branchesWithAccess,

  loggedIn: (state, getters, rootState) => rootState.auth.loggedIn,
  notificationsSorted: (state) => sortBy(
    state.notifications.filter((n) => n.display),
    'timestamp',
  ).reverse(),
  notificationToasts: (state) => state.notifications.filter((n) => n.toast),

  // eslint-disable-next-line no-unused-vars
  breadcrumbs: (state, getters) => (route) => {
    if (route.path.slice(-1) === '/') {
      const path = route.path.slice(1, -1).split('/');
      return path;
    }
    const path = route.path.substring(1).split('/');
    return path;
  },
};

const mutations = {
  setChosenPage(state, newPage) {
    state.chosenPage = newPage;
  },
  setShowSidebar(state, payload) {
    state.showSidebar = payload;
  },
  setLoading(state, payload) {
    state.pageLoading = payload;
  },
  addNotification(state, payload) {
    state.notifications.push(payload);
  },
  removeNotification(state, notificationTimestamp) {
    const notificationIndex = state.notifications
      .findIndex((notification) => notification.timestamp === notificationTimestamp);
    if (notificationIndex >= 0) {
      state.notifications.splice(notificationIndex, 1);
    }
  },
  removeAllNotifications(state) {
    state.notifications = [];
  },
};

const actions = {
  showWarning({ dispatch }, { title, text, severity = 'error' }) {
    dispatch('templateStore/templateSendNotification', {
      title,
      text,
      severity,
      toast: true,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state: sidebarState,
  getters: sidebarGetters,
  mutations,
  actions,
};
