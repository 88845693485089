import { endpoints } from '@/js/endpoints';
import moment from 'moment';
import { addObjects } from '@/js/utils';

function getDaysArray(start, end) {
  const dateArray = [];
  let currentDate = moment(start);
  const stopDate = moment(end);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

function averagedChange(series) {
  // Series is array of numbers (assumed equally spaced on x-axis)
  const n = series.length;
  if (n <= 1) {
    return 0;
  }
  // Do simple linear regression
  let y = series;
  let x = [...Array(n).keys()]; // 0, 1, ... .length-1
  const ym = y.reduce((a, b) => a + b, 0) / n;
  const xm = x.reduce((a, b) => a + b, 0) / n;
  y = y.map((i) => i - ym);
  x = x.map((i) => i - xm);
  const sx2 = x.reduce((a, b) => a + (b * b), 0);
  const sxy = x.map((e, i) => e * y[i]).reduce((a, b) => a + b, 0);
  return n * sxy / sx2;
}

function sumValues(obj, key) {
  return Object.keys(obj).reduce((sumSoFar, keyInner) => sumSoFar + obj[keyInner][key], 0);
}

const statisticsState = {
  // Filtering
  startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  queueId: null,
  focusDepartments: [],
  // Other togglables
  focusTags: {}, // swap-key: [tag_ids]
  focusRules: [],
  auto: 'both', // alt: 'true', 'false'
  tagOrder: 'abs', // alt: score
  // Data
  rawData: null,
  rawMeta: null,
  queueGroupOptions: [],
  departmentOptions: [],
  filteredData: null, // after above filter params are applied
  result: null,
  // Flags
  isFetching: false,
  isFiltering: false,
  isCrunching: false,
  isCalculating: false,
};

const statisticsGetters = {
  // Filters
  statisticsFilter: (state) => ({
    startDate: state.startDate,
    endDate: state.endDate,
    queueId: state.queueId,
    focusDepartments: state.focusDepartments,
  }),
  startDate: (state) => state.startDate,
  endDate: (state) => state.endDate,
  queueId: (state) => state.queueId,
  focusDepartments: (state) => state.focusDepartments,
  focusTags: (state) => state.focusTags,
  focusRules: (state) => state.focusRules,
  // Data
  lastUpdated: (state) => (state.rawMeta || {}).updated,
  rawData: (state) => state.rawData,
  swap2pretty: (state) => (state.rawData || {}).swap2pretty || {},
  topK: (state) => (state.rawData || {}).top_k || 1,
  filteredData: (state) => state.filteredData,
  result: (state) => state.result,
  queueGroupOptions: (state) => state.queueGroupOptions,
  departmentOptions: (state) => state.departmentOptions,
  dataExists: (state) => state.rawData != null,
  // Flags
  isFetching: (state) => state.isFetching,
  isFiltering: (state) => state.isFiltering,
  isCrunching: (state) => state.isCrunching,
  isCalculating: (state) => state.isCalculating,
};

const mutations = {
  // Flags
  setCalculating: (state, { calculating }) => {
    state.isCalculating = calculating;
  },
  setCrunching: (state, { crunching }) => {
    state.isCrunching = crunching;
  },
  setFiltering: (state, { filtering }) => {
    state.isFiltering = filtering;
  },
  setFetching: (state, { fetching }) => {
    state.isFetching = fetching;
  },
  // Data
  setRaw: (state, { data, meta }) => {
    state.rawData = data;
    state.rawMeta = meta;
    if (data != null) {
      state.queueGroupOptions = Object.entries(data.queue2pretty).map(
        ([queueId, pretty]) => ({ text: pretty, value: queueId }),
      );
      state.departmentOptions = data.department_ids || [];
    }
  },
  setFiltered: (state, { data }) => {
    state.filteredData = data;
  },
  setResult: (state, { data }) => {
    state.result = data;
  },
  setStatisticsFilter: (state, {
    startDate, endDate, queueId, focusDepartments,
  }) => {
    state.startDate = startDate;
    state.endDate = endDate;
    state.queueId = queueId;
    state.focusDepartments = focusDepartments;
  },
  setFocusTags: (state, { swapKey, focusTags }) => {
    state.focusTags[swapKey] = focusTags;
  },
  setFocusRules: (state, { focusRules }) => {
    state.focusRules = focusRules;
  },
};

const actions = {
  async refreshRawForce({ commit, dispatch, getters }) {
    if (!getters.isFetching) {
      commit('setFetching', { fetching: true });
      await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.stats}kpi/`,
      }, { root: true }).then((response) => {
        commit('setRaw', { data: response.data, meta: response.meta });
      });
      commit('setFetching', { fetching: false });
    }
  },
  async refreshRaw({ dispatch, getters }) {
    const updated = getters.lastUpdated;
    if (updated != null) {
      const then = moment(String(updated));
      const minutes = -then.diff(moment(), 'minutes');
      if (minutes >= 0 && minutes <= 60) {
        return null;
      }
    }
    return dispatch('refreshRawForce');
  },
  async doFiltering({ commit, getters }) {
    const data = getters.rawData;
    if (data != null && !getters.isFiltering) {
      commit('setFiltering', { filtering: true });
      // Filter on queue
      const departmentTimeAb = data.queue_department_time_ab[getters.queueId] || {};
      const timeDepartment = data.time_department;
      const focusDepartments = getters.focusDepartments.map((department) => {
        if (department == null) {
          return 'null';
        }
        return department.toString();
      });

      // Filter on departmentTime
      const start = getters.startDate;
      const end = getters.endDate;
      const doFilter = focusDepartments.length > 0;
      const timeAb = Object.keys(departmentTimeAb).filter(
        (department) => (doFilter ? focusDepartments.includes(department) : true),
      ).reduce((existing, department) => {
        const filtered = Object.keys(departmentTimeAb[department]).filter(
          (time) => start <= time && time <= end,
        ).reduce(
          (soFar, timeKey) => {
            // eslint-disable-next-line no-param-reassign
            soFar[timeKey] = departmentTimeAb[department][timeKey];
            return soFar;
          },
          {},
        );
        addObjects(existing, filtered);
        return existing;
      }, {});

      // Filter on timeDepartment
      const timeTotal = Object.keys(timeDepartment).filter(
        (time) => start <= time && time <= end,
      ).reduce((obj, time) => {
        let departments = Object.keys(timeDepartment[time]);
        if (doFilter) {
          departments = departments.filter(
            (department) => focusDepartments.includes(department),
          );
        }
        // eslint-disable-next-line no-param-reassign
        obj[time] = departments.reduce(
          (existing, department) => existing + timeDepartment[time][department],
          0,
        );
        return obj;
      }, {});
      commit('setFiltered', {
        data: {
          timeTotal,
          timeAb,
        },
      });
      commit('setFiltering', { filtering: false });
    }
  },

  async doCrunching({ commit, dispatch, getters }) {
    const data = getters.filteredData;
    const swap2pretty = getters.swap2pretty;
    const swaps = [null];
    swaps.push(...Object.keys(swap2pretty));
    if (data != null && !getters.isCrunching) {
      commit('setCrunching', { crunching: true });
      const out = {
        overview: {},
        swap: {},
      };
      const dates = getDaysArray(getters.startDate, getters.endDate);

      // Total tickets per time
      const timeAb = data.timeAb;
      const timeTotal = data.timeTotal;

      // Auto and manual per time (overview)
      const allRuleIds = [];
      const autoKeys = [
        'auto', 'man', 'agp', 'abp', 'abpf', 'mgp', 'mbp',
        ...Array.from({ length: getters.topK }, (_, i) => `t${i + 1}`),
      ];
      for (const swap of swaps) {
        const isOverview = swap == null;
        let timeAuto = {
          // time -> 7 numbers (an, bn, agp, abp, mgp, mbp, abpf)
          // auto: {}, man: {}, agp: {}, abp: {}, abpf: {}, mgp: {}, mbp: {},
        };
        timeAuto = Object.keys(timeAb).reduce((obj, time) => {
          const a = isOverview ? (timeAb[time].false || {}) : (
            ((timeAb[time].false || {}).swap || {})[swap] || {}
          );
          const b = isOverview ? (timeAb[time].true || {}) : (
            ((timeAb[time].true || {}).swap || {})[swap] || {}
          );
          // eslint-disable-next-line no-param-reassign
          const toAdd = {
            auto: ((a.auto || {}).n || 0) + ((b.auto || {}).n || 0),
            man: ((a.man || {}).n || 0) + ((b.man || {}).n || 0),
            agp: ((a.auto || {}).gp || 0) + ((b.auto || {}).gp || 0),
            abp: ((a.auto || {}).bp || 0) + ((b.auto || {}).bp || 0),
            abpf: ((a.auto || {}).bpf || 0) + ((b.auto || {}).bpf || 0),
            mgp: ((a.man || {}).gp || 0) + ((b.man || {}).gp || 0),
            mbp: ((a.man || {}).bp || 0) + ((b.man || {}).bp || 0),
          };
          let topmgp = toAdd.mgp;
          for (const k of Array.from({ length: getters.topK }, (_, i) => i + 1)) {
            const key = `t${k}`;
            const val = ((a.man || {})[key] || 0) + ((b.man || {})[key] || 0);
            topmgp = Math.max(topmgp, val);
            toAdd[key] = topmgp;
          }
          if (obj[time] == null) {
            // eslint-disable-next-line no-param-reassign
            obj[time] = toAdd;
          } else {
            for (const autoKey of Object.keys(toAdd)) {
              // eslint-disable-next-line no-param-reassign
              obj[time][autoKey] = (obj[time][autoKey] || 0) + toAdd[autoKey];
            }
          }
          return obj;
        }, timeAuto);
        const usage = {
          series: {},
        };
        for (const autoKey of autoKeys) {
          usage.series[autoKey] = dates.map((d) => (timeAuto[d] || {})[autoKey] || 0);
        }
        usage.series.total = dates.map(
          (d) => Math.max(
            timeTotal[d] || 0,
            Math.round((usage.series.auto[d] || 0) + (usage.series.man[d] || 0)),
          ),
        );
        usage.sum = Object.keys(usage.series).reduce((obj, series) => {
          // eslint-disable-next-line no-param-reassign
          obj[series] = usage.series[series].reduce((a, b) => a + b, 0);
          return obj;
        }, {});
        usage.series.automation = usage.series.auto.map(
          (e, i) => 100 * e / Math.max(e + usage.series.man[i], 1),
        );
        usage.sum.automation = 100 * usage.sum.auto / Math.max(usage.sum.auto + usage.sum.man, 1);
        usage.series.automationScore = usage.series.auto.map(
          (e, i) => 100 * Math.min(e + (0.5 * usage.series.mgp[i]), e + usage.series.man[i])
            / Math.max(e + usage.series.man[i], 1),
        );
        usage.sum.automationScore = 100 * Math.min(
          usage.sum.auto + (0.5 * usage.sum.mgp),
          usage.sum.auto + usage.sum.man,
        ) / Math.max(usage.sum.auto + usage.sum.man, 1);
        usage.series.coverage = usage.series.total.map(
          (e, i) => 100 * (usage.series.auto[i] + usage.series.man[i]) / Math.max(e, 1),
        );
        usage.sum.coverage = 100 * (usage.sum.auto + usage.sum.man) / Math.max(usage.sum.total, 1);
        usage.x = dates;
        const cards = {
          handled: {
            value: Math.round(usage.sum.man + usage.sum.auto),
            change: averagedChange(usage.series.auto.map((e, i) => e + usage.series.man[i])),
            title: 'Tickets Handled',
            variant: 'text-green-500',
            changeFormatType: 'rounded-number',
            tooltipText: 'Number of tickets analyzed by this queue',
          },
          automation: {
            value: usage.sum.automation,
            change: averagedChange(usage.series.automation),
            title: 'Automation',
            changeFormatType: 'percent',
            formatType: 'percent',
            tooltipText: 'Percentage of analyzed tickets that were automatically categorized',
          },
          automationScore: {
            value: usage.sum.automationScore,
            change: averagedChange(usage.series.automationScore),
            title: 'Automation Score',
            changeFormatType: 'percent',
            formatType: 'percent',
            tooltipText: 'Aggregated automation score',
          },
          coverage: {
            value: usage.sum.coverage,
            change: averagedChange(usage.series.coverage),
            title: 'Coverage',
            changeFormatType: 'percent',
            formatType: 'percent',
            tooltipText: 'Percentage of tickets that were analyzed by this queue',
          },
        };
        if (isOverview) {
          out.overview.usage = usage;
          out.overview.card = cards;
        } else {
          // Negative Feedback Ratio
          let swapData = {
            timeData: {
              // time maps to
              // nf: {}, // negative feedback count
              // n: {}, // count tickets
              // nr: {}, // tag count
              // nr: {}, // number of rules used
            },
            labelData: {
              // label maps to
              // n: {},
              // anf: {},
              // mnf: {}, // cases, auto and man negative feedback count
              // na: {}, // auto case
            },
            ruleData: {
              // ruleid: 0 (count)
            },
          };
          swapData = Object.keys(timeAb).reduce((obj, time) => {
            // eslint-disable-next-line no-param-reassign
            const a = ((timeAb[time].false || {}).swap || {})[swap] || {};
            const b = ((timeAb[time].true || {}).swap || {})[swap] || {};
            // above looks like this
            // "rule":  {
            //   "1":  3 ,
            //   "2":  3 ,
            //   "4":  30 ,
            //   "5":  16
            // } ,
            // "auto":  {
            //   "label":  {
            //     "2":  {
            //       "pos":  3 ,
            //       "neg":  0 ,
            //       "none":  2
            // } ,
            // eslint-disable-next-line no-param-reassign
            obj.ruleData = Object.keys(a.rule || {}).reduce(
              (soFar, ruleKey) => {
                // eslint-disable-next-line no-param-reassign
                soFar[ruleKey] = (soFar[ruleKey] || 0) + a.rule[ruleKey];
                return soFar;
              },
              obj.ruleData,
            );
            // eslint-disable-next-line no-param-reassign
            obj.ruleData = Object.keys(b.rule || {}).reduce(
              (soFar, ruleKey) => {
                // eslint-disable-next-line no-param-reassign
                soFar[ruleKey] = (soFar[ruleKey] || 0) + b.rule[ruleKey];
                return soFar;
              },
              obj.ruleData,
            );
            // Time data
            const Aa = (a.auto || {}).label || {};
            const Am = (a.man || {}).label || {};
            const Ba = (b.auto || {}).label || {};
            const Bm = (b.man || {}).label || {};
            if (obj.timeData[time] == null) {
              // eslint-disable-next-line no-param-reassign
              obj.timeData[time] = {
                nf: 0,
                n: 0,
                nt: 0,
                nr: 0,
              };
            }
            const td = obj.timeData[time];
            td.nr += Object.values(a.rule || {}).reduce((soFar, value) => soFar + value, 0);
            td.nr += Object.values(b.rule || {}).reduce((soFar, value) => soFar + value, 0);
            td.nf += sumValues(Aa, 'neg') + sumValues(Am, 'neg');
            td.nf += sumValues(Ba, 'neg') + sumValues(Bm, 'neg');
            td.nt += sumValues(Aa, 'n') + sumValues(Am, 'n');
            td.nt += sumValues(Ba, 'n') + sumValues(Bm, 'n');
            td.n += ((a.auto || {}).n || 0) + ((b.auto || {}).n || 0);
            td.n += ((a.man || {}).n || 0) + ((b.man || {}).n || 0);
            // Label data
            let allLabels = [].concat.apply([], [
              Object.keys(Aa), Object.keys(Am), Object.keys(Ba), Object.keys(Bm),
            ]);
            allLabels = [...new Set(allLabels)];
            // eslint-disable-next-line no-param-reassign
            obj.labelData = allLabels.reduce(
              (soFar, labelKey) => {
                // eslint-disable-next-line no-param-reassign
                if (soFar[labelKey] == null) {
                  // eslint-disable-next-line no-param-reassign
                  soFar[labelKey] = {
                    na: 0, n: 0, anf: 0, mnf: 0,
                  };
                }
                const aa = Aa[labelKey] || {};
                const am = Am[labelKey] || {};
                const ba = Ba[labelKey] || {};
                const bm = Bm[labelKey] || {};
                // eslint-disable-next-line no-param-reassign
                soFar[labelKey].n += (aa.n || 0) + (ba.n || 0) + (am.n || 0) + (bm.n || 0);
                // eslint-disable-next-line no-param-reassign
                soFar[labelKey].na += (aa.n || 0) + (ba.n || 0);
                // eslint-disable-next-line no-param-reassign
                soFar[labelKey].anf += (aa.neg || 0) + (ba.neg || 0);
                // eslint-disable-next-line no-param-reassign
                soFar[labelKey].mnf += (am.neg || 0) + (bm.neg || 0);
                return soFar;
              },
              obj.labelData,
            );
            return obj;
          }, swapData);
          const nfrSeries = dates.map(
            // eslint-disable-next-line max-len
            (x) => (
              (swapData.timeData[x] || {}).nf || 0
            ) / Math.max(1, (swapData.timeData[x] || {}).nt || 0),
          );
          usage.series.nfr = nfrSeries;
          cards.nfr = {
            // eslint-disable-next-line max-len
            value: 100 * dates.map(
              (x) => (swapData.timeData[x] || {}).nf || 0,
            ).reduce(
              (a, b) => a + b,
              0,
            ) / Math.max(
              dates.map((x) => (swapData.timeData[x] || {}).nt || 0).reduce((a, b) => a + b, 0),
              1,
            ),
            change: averagedChange(nfrSeries),
            title: 'Negative Feedback Ratio',
            changeFormatType: 'percent',
            formatType: 'percent',
            tooltipText: 'Percentage of tickets that received negative feedback',
            upVariant: 'text-red-500',
            downVariant: 'text-green-500',
          };
          const nrSeries = dates.map(
            // eslint-disable-next-line max-len
            (x) => (
              (swapData.timeData[x] || {}).nr || 0
            ) / Math.max(1, (swapData.timeData[x] || {}).n || 0),
          );
          usage.series.rules = nrSeries;
          cards.rules = {
            value: dates.map(
              (x) => (swapData.timeData[x] || {}).nr || 0,
            ).reduce(
              (a, b) => a + b,
              0,
            ) / Math.max(dates.map(
              (x) => (swapData.timeData[x] || {}).n || 0,
            ).reduce(
              (a, b) => a + b,
              0,
            ), 1),
            change: averagedChange(nrSeries),
            title: 'Rules Used per Ticket',
            changeFormatType: 'rounded-number',
            formatType: 'rounded-number',
            tooltipText: 'Average number of rules applied per ticket',
          };
          allRuleIds.push(...Object.keys(swapData.ruleData));
          out.swap[swap] = {
            usage, card: cards, swapData,
          };
        }
      }
      dispatch(
        'customrules/updateUnknownStagedRules',
        { newIds: [...new Set(allRuleIds)] },
        { root: true },
      );
      commit('setResult', {
        data: out,
      });
      commit('setCrunching', { crunching: false });
    }
  },

  async doCalculations({ commit, dispatch, getters }) {
    if (!getters.isCalculating) {
      commit('setCalculating', { calculating: true });
      await dispatch('refreshRaw');
      await dispatch('doFiltering');
      await dispatch('doCrunching');
      commit('setCalculating', { calculating: false });
    }
  },
};

export default {
  namespaced: true,
  state: statisticsState,
  getters: statisticsGetters,
  mutations,
  actions,
};
