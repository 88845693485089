import { endpoints } from '@/js/endpoints';

const entityState = {
  tableOptions: {},

  // Tmp attributes
  positiveTags: [],
  negativeTags: [],
  cachedFlatTagList: [],
  cachedFlatTag2Id: null,
  flatTagListFilter: null,
  completions: [],
  restrictedTagsNumber: 0,
};

function prettify(pks, tags) {
  return pks.map(((t) => {
    const dict = tags.pk2tag[t];
    if (dict == null) {
      return null;
    }
    return {
      id: t,
      display: dict.display,
      color: tags.field2color[dict.field],
    };
  }));
}

function filterFlatTags(tags, fieldIds) {
  if (tags) {
    let fL = tags.flatList;
    if (fieldIds != null) {
      fL = fL.filter((x) => fieldIds.includes(x.field_id));
    }
    return fL.map((x) => `#${x.field}: ${x.display}`);
  }
  return [];
}

const entityGetters = {
  getTableOptions: (state) => (pk) => state.tableOptions[pk] || [],
  positiveTags: (state) => JSON.parse(JSON.stringify(state.positiveTags)),
  negativeTags: (state) => JSON.parse(JSON.stringify(state.negativeTags)),
  prettyPositive: (state, otherGetters, rootState) => prettify(state.positiveTags, rootState.tags),
  prettyNegative: (state, otherGetters, rootState) => prettify(state.negativeTags, rootState.tags),
  flatTagList(state, otherGetters, rootState) {
    if (state.cachedFlatTagList.length === 0) {
      state.cachedFlatTagList = filterFlatTags(rootState.tags, state.flatTagListFilter);
    }
    return state.cachedFlatTagList;
  },
  flatTag2Id(state, otherGetters, rootState) {
    if (state.cachedFlatTag2Id === null) {
      state.cachedFlatTag2Id = rootState.tags.flatList.reduce((acc, cur) => Object.assign(acc, { [`#${cur.field}: ${cur.display}`]: cur.id }), {});
    }
    return state.cachedFlatTag2Id;
  },
  completions: (state) => state.completions,
  flatTagListFilter: (state) => state.flatTagListFilter,
  restrictedTagsNumber: (state) => state.restrictedTagsNumber,
};

function addElement(list, element) {
  if (!list.includes(element)) {
    list.push(element);
  }
}

function moveElement(source, target, index) {
  /*
  Moves element from source array at index to target array
  if index is null the whole array is moved
   */
  if (index === null) {
    target.push(...source.splice(0, source.length).filter((x) => !target.includes(x)));
  } else {
    target.push(...source.splice(index, 1).filter((x) => !target.includes(x)));
  }
}

function removeElement(source, index) {
  if (index === null) {
    source.splice(0, source.length);
  } else {
    source.splice(index, 1);
  }
}

const mutations = {
  updateTableOptions(state, { pk, options }) {
    state.tableOptions[pk] = options;
  },
  moveTag(state, { down, index }) {
    if (down) {
      moveElement(state.positiveTags, state.negativeTags, index);
    } else {
      moveElement(state.negativeTags, state.positiveTags, index);
    }
  },
  addTag(state, { down, pk }) {
    const toAdd = parseInt(pk, 10);
    if (Number.isInteger(toAdd)) {
      if (down) {
        addElement(state.negativeTags, toAdd);
      } else {
        addElement(state.positiveTags, toAdd);
      }
    }
  },
  removeTag(state, { down, index }) {
    if (down) {
      removeElement(state.negativeTags, index);
    } else {
      removeElement(state.positiveTags, index);
    }
  },
  clearTags(state) {
    state.positiveTags = [];
    state.negativeTags = [];
    state.restrictedTagsNumber = 0;
  },
  setTags(state, { pos, neg }) {
    state.positiveTags = pos;
    state.negativeTags = neg;
  },
  setFlatListFilter(state, { fieldIds, flatList }) {
    state.flatTagListFilter = fieldIds;
    state.cachedFlatTagList = flatList;
  },
  removeCompletion(state, value) {
    state.completions.splice(state.completions.indexOf(value), 1);
  },
  restoreCompletion(state, value) {
    state.completions.push(value);
  },
  setCompletions(state, value) {
    state.completions = value;
  },
  setRestrictedTagsNumber(state, value) {
    state.restrictedTagsNumber = value;
  },
};

const actions = {
  async refreshOptions({ commit, dispatch }, { pk }) {
    return dispatch(
      'auth/callBackendSuccess',
      { url: `${endpoints.tableOptions + pk}/` },
      { root: true },
    ).then(
      (data) => {
        if (data !== undefined) {
          const opts = data.options.map((e) => ({
            value: e.unid,
            text: e.display,
            pk: e.pk,
          }));
          commit('updateTableOptions', {
            options: opts || [], pk,
          });
          return opts;
        }
        return undefined;
      },
    );
  },
  async ensureOptions({ state, dispatch }, { pk }) {
    let out = state.tableOptions[pk];
    if (out === undefined) {
      out = await dispatch('refreshOptions', { pk });
    }
    return out;
  },
  changeTagListFilter({ commit, rootState }, { fieldIds }) {
    commit('setFlatListFilter', { fieldIds, flatList: filterFlatTags(rootState.tags, fieldIds) });
  },
  setCompletions({ commit, getters, state }, filter) {
    commit('setCompletions', JSON.parse(JSON.stringify(getters.flatTagList)));
    if (filter) {
      getters.prettyNegative.forEach((element) => {
        if (element) {
        // eslint-disable-next-line no-unused-vars
          const found = Object.entries(getters.flatTag2Id).find(([k, v]) => v === `${element.id}`);
          if (found) {
            commit('removeCompletion', found[0]);
          }
        } else {
          commit('setRestrictedTagsNumber', state.restrictedTagsNumber + 1);
        }
      });
      getters.prettyPositive.forEach((element) => {
        if (element) {
        // eslint-disable-next-line no-unused-vars
          const found = Object.entries(getters.flatTag2Id).find(([k, v]) => v === `${element.id}`);
          if (found) {
            commit('removeCompletion', found[0]);
          }
        } else {
          commit('setRestrictedTagsNumber', state.restrictedTagsNumber + 1);
        }
      });
    }
  },
  removeCompletion({ commit, getters }, id) {
    // eslint-disable-next-line no-unused-vars
    const found = Object.entries(getters.flatTag2Id).find(([k, v]) => v === `${id}`);
    commit('removeCompletion', found[0]);
  },
  restoreCompletion({ commit, getters }, id) {
    // eslint-disable-next-line no-unused-vars
    const found = Object.entries(getters.flatTag2Id).find(([k, v]) => v === `${id}`);
    commit('restoreCompletion', found[0]);
  },
  restoreAllCompletions({ commit, getters }, down) {
    if (down) {
      getters.prettyNegative.forEach((element) => {
        // eslint-disable-next-line no-unused-vars
        const found = Object.entries(getters.flatTag2Id).find(([k, v]) => v === `${element.id}`);
        commit('restoreCompletion', found[0]);
      });
    } else {
      getters.prettyPositive.forEach((element) => {
        // eslint-disable-next-line no-unused-vars
        const found = Object.entries(getters.flatTag2Id).find(([k, v]) => v === `${element.id}`);
        commit('restoreCompletion', found[0]);
      });
    }
  },
};

export default {
  namespaced: true,
  state: entityState,
  getters: entityGetters,
  mutations,
  actions,
};
