import { endpoints } from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';
import GeneralTemplate from '@/js/vuexTemplates/general';

const trainedModelState = {};

const trainedModelGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.simpleTrainedModel, 'trainedModel'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: trainedModelState, getters: trainedModelGetters, mutations, actions,
  }, templates),
};
