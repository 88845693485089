import { endpoints } from '@/js/endpoints';
import moment from 'moment';

const taskState = {
  // Task store
  id2task: {
    // pk: { data: {}, callbackSuccess, callbackFailed, callbackUpdate },
  },
  refreshing: false,

  // Task queue readiness info
  type2ready: {
    // taskType: bool,
  },

  // Selected/active task ids
  type2activeId: {
    // taskType: pk
  },
  // Used in TaskStatus page
  taskListActiveId: null,
};

const taskGetters = {
  id2task: (state) => state.id2task,
  getTaskData: (state) => (pk) => (state.id2task[pk] || {}).data || null,
  type2activeId: (state) => state.type2activeId,
  activeTaskId: (state) => (taskType) => state.type2activeId[taskType] || null,
  queueReady: (state) => (taskType) => state.type2ready[taskType] || false,
  activeTaskListId: (state) => state.taskListActiveId,
};

const mutations = {
  addTask: (state, {
    pk, data, callbackSuccess, callbackFailed, callbackUpdate,
  }) => {
    // console.log(`add task ${pk}`);
    state.id2task[pk] = {
      data, callbackSuccess, callbackFailed, callbackUpdate,
    };
  },
  removeTask(state, pk) {
    // console.log(`remove task ${pk}`);
    delete state.id2task[pk];
  },
  clearTasks(state) {
    state.id2task = {};
  },
  updateTask: (state, { pk, data }) => {
    if (!(pk in state.id2task)) {
      state.id2task[pk] = {};
    }
    state.id2task[pk].data = data;
  },
  setActiveTaskId: (state, { taskType, id }) => {
    state.type2activeId[taskType] = id;
  },
  setActiveTaskListId: (state, pk) => {
    state.taskListActiveId = pk;
  },
  setQueueReadiness: (state, { type2ready }) => {
    state.type2ready = type2ready;
  },
  setRefreshing: (state, refreshing) => {
    state.refreshing = refreshing;
  },
};

const actions = {
  async refresh({ dispatch, commit, getters }) {
    if (!getters.refreshing) {
      commit('setRefreshing', true);
      await dispatch('refreshAll');
      commit('setRefreshing', false);
    }
  },
  async refreshAll({ dispatch }) {
    // Runs promises in parallel
    const task1 = dispatch('refreshQueueStatus');
    const task2 = dispatch('refreshTasks');
    return {
      task1: await task1,
      task2: await task2,
    };
  },
  async refreshQueueStatus({ dispatch, commit }) {
    await dispatch(
      'auth/callBackendSuccess',
      {
        url: endpoints.taskQueueStatus,
        handleRespError: false,
      },
      { root: true },
    ).then(
      (counts) => {
        const ready = {};
        for (const key of Object.keys(counts)) {
          ready[key] = counts[key] <= 3;
        }
        commit('setQueueReadiness', { type2ready: ready });
      },
    );
  },
  async refreshTasks({
    dispatch, commit, state, getters,
  }) {
    // Of all above tasks, find those whose status is waiting or process and
    // modified less than a day ago
    const toUpdate = [];
    const now = moment().unix();
    for (const pk of Object.keys(state.id2task)) {
      const taskData = getters.getTaskData(pk);
      if (taskData == null) {
        // It was added by addTask - no task info yet, only callbacks
        toUpdate.push(pk);
      } else if (
        (['waiting', 'process'].includes(taskData.status) && taskData.modified_date != null)
      ) {
        // Fetch if relevant status and not more than a day since last modified
        const modifiedDate = moment(String(taskData.modified_date)).unix();
        if (now - modifiedDate < 24 * 60 * 60) {
          toUpdate.push(pk);
        }
      }
    }

    // Update tasks if any
    if (toUpdate.length > 0) {
      const id2task = state.id2task;
      await dispatch(
        'auth/callBackendSuccess',
        {
          url: endpoints.taskHandling,
          params: {
            ids: toUpdate,
          },
          handleRespError: false,
        },
        { root: true },
      ).then(
        (data) => {
          const updated = [];
          for (const row of data.results) {
            updated.push(row.id.toString());
            commit('updateTask', { pk: row.id, data: row });
            const task = id2task[row.id];
            let callback;
            if (row.status === 'process') {
              callback = task.callbackUpdate;
            } else if (row.status === 'failed') {
              callback = task.callbackFailed;
            } else if (row.status === 'success') {
              callback = task.callbackSuccess;
            }
            if (callback) {
              callback(row);
            }
          }

          // Tasks we did not get answers to are not to be called again next time
          const difference = toUpdate.filter((x) => !updated.includes(x));
          for (const pk of difference) {
            commit('removeTask', pk);
          }
        },
      );
    }
  },
};

export default {
  namespaced: true,
  state: taskState,
  getters: taskGetters,
  mutations,
  actions,
};
