import { endpoints } from '@/js/endpoints';
// eslint-disable-next-line import/no-cycle
import { collectPaginatedContent } from '@/js/apiHandlers';

const hash = require('object-hash');

function reindexSessionTags(tags) {
  // input format: [{id, key, value}]
  // output format: {id: {key, value}} and [{id, key, value}] (sorted)
  const out = {
    id2tag: {},
    key2value2id: {},
  };
  for (const e of tags) {
    out.id2tag[e.id] = e;
    if (!(e.key in out.key2value2id)) {
      out.key2value2id[e.key] = {};
    }
    out.key2value2id[e.key][e.value] = e.id;
  }
  function compare(a, b) {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }
  out.tagList = tags.sort(compare);
  return out;
}

const routingState = {
  // Queue Group
  group: null,
  queue: null,
  allQueues: [],

  // Session tags: id2tag, flatList
  sessionTags: null,
  selectedTags: [], // ids
  negateSelected: [], // ids (which ids, if present in selectedTags, are exclusion)

  // Ticket explain
  ticketId: null,
  ticketData: null,
  ticketHistoryEntry: null,
  visibleTypes: ['ticket', 'ai', 'routing', 'feedback', 'anomaly'],
};

const getters = {
  group: (state) => state.group,
  queue: (state) => state.queue,
  allQueues: (state) => state.allQueues,
  sessionTags: (state) => state.sessionTags,
  selectedTags: (state) => state.selectedTags,
  filterTags: (state) => state.selectedTags.filter((x) => !state.negateSelected.includes(x)),
  excludeTags: (state) => state.selectedTags.filter((x) => state.negateSelected.includes(x)),
  ticketId: (state) => state.ticketId,
  ticketData: (state) => state.ticketData,
  ticketDataReady: (state) => state.ticketId != null && state.ticketData != null,
  ticketHistoryEntry: (state) => state.ticketHistoryEntry,
  visibleTypes: (state) => state.visibleTypes,
};

const mutations = {
  showType: (state, { type }) => {
    if (!state.visibleTypes.includes(type)) {
      state.visibleTypes.push(type);
    }
  },
  hideType: (state, { type }) => {
    const idx = state.visibleTypes.indexOf(type);
    if (idx > -1) {
      state.visibleTypes.splice(idx, 1);
    }
  },
  setTicket: (state, { id, data }) => {
    let history = data.history;
    if (history != null) {
      history = history.map((x) => ({ unid: hash(x), ...x }));
    }
    state.ticketId = id;
    state.ticketData = data;
    state.ticketData.history = history;
  },
  clearTicket: (state) => {
    state.ticketId = null;
    state.ticketData = null;
  },
  setTicketHistoryEntry: (state, { entry }) => {
    state.ticketHistoryEntry = entry;
  },
  clearTicketHistoryEntry: (state) => {
    state.ticketHistoryEntry = null;
  },
  setGroup: (state, { group }) => {
    state.group = group;
  },
  setQueue: (state, queue) => {
    state.queue = queue;
  },
  setAllQueues: (state, { allQueues }) => {
    state.allQueues = allQueues;
  },
  setSessionTags(state, newTags) {
    state.sessionTags = reindexSessionTags(newTags);
  },
  setIncludeTags(state, tags) {
    state.selectedTags = tags;
  },
  setExcludeTags(state, tags) {
    state.negateSelected = tags;
  },
};

const actions = {
  // flipType({ state, commit }, { type }) {
  //   if (state.visibleTypes)
  // },
  async refreshQueues({ commit }) {
    await collectPaginatedContent(endpoints.queue, 0, null).then((result) => {
      commit('setAllQueues', {
        allQueues: result,
      });
    });
  },
  async ensureSessionTags({ state, commit }, { force = false }) {
    if (state.sessionTags === null || force) {
      const tags = await collectPaginatedContent(endpoints.sessionTag);
      commit('setSessionTags', tags);
    }
    return state.sessionTags;
  },
};

export default {
  namespaced: true,
  state: routingState,
  getters,
  mutations,
  actions,
};
