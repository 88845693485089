import { endpoints } from '@/js/endpoints';
// eslint-disable-next-line import/no-cycle
import { collectPaginatedContent } from '@/js/apiHandlers';

const swapsState = {
  swapUnits: null,
};

const getters = {
  swapUnits: (state) => state.swapUnits,
  swapUnitOptions: (state) => state.swapUnits.map((x) => ({ value: x.id, text: x.name })),
};

const mutations = {
  setSwapUnits: (state, { data }) => {
    state.swapUnits = data;
  },
};

const actions = {
  async refreshSwapUnits({ commit }) {
    await collectPaginatedContent(endpoints.swapUnit, 0, null).then((result) => {
      commit('setSwapUnits', {
        data: result,
      });
    });
  },
  async ensureSwapUnits({ state, commit }, { force = false }) {
    if (state.swapUnit === null || force) {
      const result = await collectPaginatedContent(endpoints.swapUnit);
      commit('setSwapUnits', { data: result });
    }
    return state.swapUnit;
  },
};

export default {
  namespaced: true,
  state: swapsState,
  getters,
  mutations,
  actions,
};
