// eslint-disable-next-line import/no-cycle
import store from '@/store';

// eslint-disable-next-line vue/max-len
export const getContent = (url, currentContent, resolve, reject, resultHandler = null, params = {}) => {
  store.dispatch('auth/callBackendSuccess', {
    url, params,
  }).then((response) => {
    if (resultHandler !== null) {
      resultHandler(response.results);
    }
    const retrievedContent = currentContent.concat(response.results);
    if (response.next !== null) {
      getContent(
        response.next.replace('http://', 'https://'),
        retrievedContent,
        resolve,
        reject,
        resultHandler,
      );
    } else {
      resolve(retrievedContent);
    }
  }).catch((error) => {
    console.log(error);
    reject('Something went wrong, please refresh');
  });
};

// eslint-disable-next-line vue/max-len
export function collectPaginatedContent(rootURL, start = 0, end = null, resultHandler = null, params = {}) {
  if (Number.isSafeInteger(end)) {
    const url = `${`${rootURL}?limit=${end}` - start}&offset=${start}`;
    return store.dispatch('callBackendSuccess', {
      url,
      params: {
        ...params,
        limit: end,
        offset: start,
      },
    }).then(
      (data) => {
        if (resultHandler !== null) {
          resultHandler(data.results);
        }
        return data.results;
      },
    );
  }
  return new Promise((resolve, reject) => {
    getContent(rootURL, [], resolve, reject, resultHandler, params);
  });
}
