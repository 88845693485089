// eslint-disable-next-line import/no-cycle
import store from '@/store/index';

export function getDisplayFromId(key, value) {
  let display;
  switch (key) {
    case 'ruleId':
      display = store.getters['customrules/getNameFromId'](value);
      if (!display) {
        display = 'Single';
      }
      break;
    default: display = 'empty';
  }

  return display;
}

export function getBreadcrumb(match, params) {
  // if the path ends with / or is empty, it means it is some route that is accessed
  // from base route, so it already has a match
  if (match.path.charAt(match.path.length - 1) === '/') {
    return null;
  }
  if (!match.path.length) {
    return null;
  }

  let paramKey;
  if (match.path.includes('ruleId')) {
    paramKey = 'ruleId';
  }
  const paramValue = params[paramKey];

  // create link and display name
  const link = { name: match.name, query: { paramKey: paramValue } };
  const display = getDisplayFromId(paramKey, paramValue);

  if (paramKey === 'ruleId') {
    return [{ display: 'Manage', link: { name: 'ruleHome' } }, { display, link }];
  }
  return { display, link };
}
