import { endpoints } from '@/js/endpoints';
// eslint-disable-next-line import/no-cycle
import { collectPaginatedContent } from '@/js/apiHandlers';

const customRuleState = {
  group: null, // Rule Collection Group
  ruleCollectionId: null,
  ruleCollection: { name: null, rules: [] },
  dataCollection: {},
  suggestions: [],
  testCases: [],
  activeRule: null,
  modalInfo: {
    action: null,
    type: null,
    title: null,
  },

  allCollections: [],

  // pk to object mapping (name, priority, rule_content_json)
  stagedRules: {},

  ruleUsageStats: {},

  // rules applied
  rulesApplied: null,
  suggestionsApplied: null,
  rules: {},
  isFetching: false,
};
function emptyRule() {
  return JSON.parse(JSON.stringify({
    positive_tags: [],
    negative_tags: [],
    text_conditions: [],
    actions: [],
    stop_if_applies: false,
    apply_if_below_confidence: 0.75,
  }));
}
const getters = {
  group: (state) => state.group,
  ruleCollectionId: (state) => state.ruleCollectionId,
  allCollections: (state) => state.allCollections,
  stagedRule: (state) => (id) => state.stagedRules[id],
  allStagedRules: (state) => state.stagedRules,
  ruleCollectionContent(state) {
    return state.allCollections.find((x) => x.unid === state.ruleCollection.unid);
  },
  rulesApplied: (state) => state.rulesApplied,
  suggestionsApplied: (state) => state.suggestionsApplied,
  getNameFromId: (state) => (id) => {
    const ruleCollection = Object.values(state.allCollections).find((e) => e.unid === id);
    return ruleCollection ? ruleCollection.name : null;
  },
  isFetching: (state) => state.isFetching,
  ruleCollection: (state) => state.ruleCollection,
  dataCollection: (state) => state.dataCollection,
  suggestions: (state) => state.suggestions,
  activeRule: (state) => state.activeRule,
  modalInfo: (state) => state.modalInfo,
  testCases: (state) => state.testCases.filter((x) => x.rule_collection === state.ruleCollectionId),
  ruleUsageStats: (state) => state.ruleUsageStats[state.ruleCollectionId],
};

const mutations = {
  setGroup: (state, { group }) => {
    state.group = group;
  },
  setRuleCollectionId: (state, ruleCollectionId) => {
    state.ruleCollectionId = ruleCollectionId;
  },
  setAllCollections: (state, { allCollections }) => {
    state.allCollections = allCollections;
  },
  updateStagedRules: (state, { stagedRules }) => {
    state.stagedRules = { ...state.stagedRules, ...stagedRules };
  },
  setRulesApplied: (state, rulesApplied) => {
    state.rulesApplied = rulesApplied;
  },
  setSuggestionsApplied: (state, suggestionsApplied) => {
    state.suggestionsApplied = suggestionsApplied;
  },
  setIsFetching: (state, value) => {
    state.isFetching = value;
  },
  setRuleCollection: (state, ruleCollection) => {
    state.ruleCollection = ruleCollection;
  },
  setDataCollection: (state, dataCollection) => {
    state.dataCollection = dataCollection;
  },
  setSuggestions: (state, suggestions) => {
    state.suggestions = suggestions;
  },
  setActiveRule: (state, value) => {
    state.activeRule = value;
  },
  setModalInfo: (state, { property, value }) => {
    switch (property) {
      case 'action': state.modalInfo.action = value; break;
      case 'title': state.modalInfo.title = value; break;
      case 'type': state.modalInfo.type = value; break;
      default: break;
    }
  },
  setTestCases: (state, testCases) => {
    state.testCases = testCases;
  },
  updateTestCases: (state, testCase) => {
    const index = state.testCases.findIndex((t) => t.unid === testCase.unid);
    if (index === -1) return;
    state.testCases.splice(index, 1, testCase);
  },
  setRuleUsageStats: (state, usageStats) => {
    state.ruleUsageStats[state.ruleCollectionId] = usageStats;
  },
};

const actions = {
  fetchAllCollections({ commit, dispatch }) {
    const promise = dispatch(
      'auth/callBackendSuccess',
      { url: `${endpoints.ruleCollection}` },
      { root: true },
    );

    return promise.then((data) => {
      commit('setAllCollections', { allCollections: data.results });
    });
  },
  setRulesApplied({ commit }, { rulesApplied }) {
    commit('setRulesApplied', rulesApplied);
  },
  setSuggestionsApplied({ commit }, { suggestionsApplied }) {
    commit('setSuggestionsApplied', suggestionsApplied);
  },
  async setRuleCollectionAndDataCollection({ state, dispatch, commit }, { ruleCollection }) {
    commit('setRuleCollectionId', ruleCollection);
    if (ruleCollection) {
      const rc = state.allCollections.find((x) => x.unid === ruleCollection);
      if (rc && rc.group) {
        await dispatch('ai/setTicketForm', {
          dcUnid: rc.group.data_collection_unid,
        }, { root: true });
      }
    }
  },
  async refreshCollections({ commit }) {
    await collectPaginatedContent(endpoints.ruleCollection, 0, null).then((result) => {
      commit('setAllCollections', {
        allCollections: result,
      });
    });
  },
  async updateUnknownStagedRules({ commit, state }, { newIds }) {
    const existing = Object.keys(state.stagedRules);
    const ids = newIds.filter((x) => !existing.includes(x));
    if (ids.length > 0) {
      await collectPaginatedContent(endpoints.stagedRule, 0, null, null, {
        rule_ids: ids,
      }).then((result) => {
        const stagedRules = {};
        for (const rule of result) {
          stagedRules[rule.id] = rule;
        }
        commit('updateStagedRules', { stagedRules });
      });
    }
  },
  async refreshRuleCollection({
    commit, dispatch, state, rootGetters,
  }, { refreshing }) {
    if (state.ruleCollectionId == null) {
      return;
    }
    if (!refreshing) {
      commit('setIsFetching', true);
    }
    let url = `${endpoints.ruleCollection + state.ruleCollectionId}/`;
    const ruleCollection = await dispatch('auth/callBackendSuccess', { url }, { root: true });
    commit('setRuleCollection', ruleCollection);
    url = `${endpoints.ruleCollectionGroup + ruleCollection.group.unid}/`;
    const groupData = await dispatch('auth/callBackendSuccess', { url }, { root: true });

    const dataCollection = groupData.data_collection;
    dataCollection.fieldConfigId2Name = {};
    dataCollection.allTextFields = [];
    dataCollection.fieldConfigId2fieldId = {};
    const fieldIds = [];
    for (const [key, value] of Object.entries(dataCollection.fields)) {
      if (value.field_config_id != null) {
        dataCollection.fieldConfigId2Name[value.field_config_id] = key;
        if (value.field_id != null) {
          dataCollection.fieldConfigId2fieldId[value.field_config_id] = value.field_id;
          if (!fieldIds.includes(value.field_id) && ['catlabel', 'cat'].includes(value.moma_type)) {
            fieldIds.push(value.field_id);
          }
        }
      }
      if (value.moma_type === 'text') {
        dataCollection.allTextFields.push(value.field_config_id);
      }
    }
    dispatch('entity/changeTagListFilter', { fieldIds }, { root: true });
    commit('setDataCollection', dataCollection);
    if (['administrator', 'manager'].includes(rootGetters.role)) {
      const suggestions = await dispatch('auth/callBackendSuccess', {
        url: endpoints.suggestedRule,
        method: 'GET',
        params: { collection_id: this.ruleCollectionId },
      }, { root: true });
      commit('setSuggestions', suggestions.results);
    }
    if (rootGetters.role === 'administrator') {
      const testCases = await dispatch('auth/callBackendSuccess', {
        url: endpoints.testCase,
        method: 'GET',
      }, { root: true });
      commit('setTestCases', testCases.results);
    }
    commit('setIsFetching', false);
  },
  setActiveRule({
    commit, dispatch, state, rootGetters,
  }, unid) {
    if (unid !== undefined) {
      let activeRule;
      if (unid === null) {
        commit('setModalInfo', { property: 'type', value: rootGetters.role === 'administrator' ? 'rule' : 'suggestion' });
        activeRule = {
          rule_collection: state.ruleCollectionId,
          name: null,
          priority: Math.max(1, Math.max(...state.ruleCollection.rules.map((o) => o.priority)) + 1),
          description: '',
          rule_content_json: emptyRule(),
          active: true,
        };
        commit('entity/clearTags', {}, { root: true });
        dispatch('entity/setCompletions', false, { root: true });
      } else {
        let rule = state.ruleCollection.rules.find((x) => x.unid === unid);
        if (rule) {
          commit('setModalInfo', { property: 'type', value: 'rule' });
        } else {
          rule = state.suggestions.find((x) => x.unid === unid);
          commit('setModalInfo', { property: 'type', value: 'suggestion' });
        }
        activeRule = JSON.parse(JSON.stringify(rule));
        activeRule.rule_content_json = JSON.parse(activeRule.rule_content_json);
        commit('entity/setTags', {
          pos: activeRule.rule_content_json.positive_tags,
          neg: activeRule.rule_content_json.negative_tags,
        }, { root: true });
        dispatch('entity/setCompletions', true, { root: true });
      }
      commit('setActiveRule', activeRule);
    }
    return true;
  },
  async fetchRuleUsageStats({ commit, dispatch, state }) {
    const url = `${endpoints.ruleCollection + state.ruleCollectionId}/rule_usage_stats/`;
    const usageStats = await dispatch('auth/callBackendSuccess', { url }, { root: true });
    commit('setRuleUsageStats', usageStats);
  },
};

export default {
  namespaced: true,
  state: customRuleState,
  getters,
  mutations,
  actions,
};
