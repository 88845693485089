import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import SupWizPrimeVue from 'supwiz/primevue/plugin';

import PermissionGuard from '@/components/PermissionGuard.vue';
import FloatingContainer from '@/components/FloatingContainer.vue';

import app from '@/app';
import router from '@/router';
import store from '@/store';

import '@/js/icons';
import '@/assets/tailwind.css';

moment.tz.setDefault('Europe/Copenhagen');

// PrimeVue setup
app.use(SupWizPrimeVue);

// Custom components
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FloatingContainer', FloatingContainer);
app.component('PermissionGuard', PermissionGuard);

app.config.productionTip = false;

app.use(router);
app.use(store);

app.mount('#app');
