import Cookies from 'js-cookie';
import { createRouter, createWebHistory } from 'vue-router';

import PassThrough from '@/components/PassThrough.vue';
// eslint-disable-next-line import/no-cycle
import { getBreadcrumb } from '@/js/breadcrumbs';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import { endpoints } from '@/js/endpoints';

async function defaultCheckPermission(to, from, next) {
  let authStatus;
  if (!store.getters['auth/loggedIn']) {
    store.commit('auth/setTopMessage', null);
    authStatus = await store.dispatch('authentication');
  }
  if (authStatus === 'no token') {
    store.commit('sidebar/setChosenPage', null);
    next({ name: 'login' });
  } else if (store.getters['auth/featurePermissions'][to.name] !== 'no') {
    // console.log('you may pass');
    store.commit('sidebar/setChosenPage', to.name);
    next();
  } else {
    // console.log(`user does not have access to ${to.name}`);
    next(false);
  }
}

const routes = [
  {
    path: '/',
    component: () => import('@/pages/HomePage.vue'),
    name: 'home',
    beforeEnter: defaultCheckPermission,
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/',
    component: PassThrough,
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
    children: [
      {
        path: '/login',
        component: () => import('@/pages/LoginPage.vue'),
        name: 'login',
        meta: {
          breadcrumb: { display: ' ', link: null },
        },
      },
      {
        path: '/login-supwiz',
        name: 'login-supwiz',
        beforeEnter: () => window.location.replace(endpoints.ssoLoginSupwiz),
      },
      {
        path: '/logout',
        component: () => import('@/pages/LogoutPage.vue'),
        name: 'logout',
        meta: {
          breadcrumb: { display: ' ', link: null },
        },
      },
    ],
  },
  {
    path: '/aad-auth',
    name: 'aad-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateToken', accessToken);
          store.commit('auth/updateRefresh', refreshToken);
          if (originalPath) {
            next(originalPath);
          } else {
            next({ name: 'home' });
          }
        } else {
          next({ name: 'login', query: { aad_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { aad_error: to.query.aad_error, originalPath } });
      }
    },
  },
  {
    path: '/sso-auth',
    name: 'sso-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateToken', accessToken);
          store.commit('auth/updateRefresh', refreshToken);
          if (originalPath && originalPath !== 'login') {
            next(originalPath);
          } else {
            next({ name: 'home' });
          }
        } else {
          next({ name: 'login', query: { aad_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { aad_error: to.query.aad_error, originalPath } });
      }
    },
  },

  {
    path: '/ai',
    name: 'ai',
    component: PassThrough,
    redirect: 'ai/testing-site',
    meta: {
      breadcrumb: { display: 'AI', link: { name: 'ai' } },
    },
    children: [
      {
        path: 'testing-site',
        component: () => import('@/pages/AI/TestSite.vue'),
        name: 'ai_test_site',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Testing Site', link: { name: 'ai_test_site' } },
        },
      },
      {
        path: 'usage',
        component: () => import('@/pages/AI/Usage.vue'),
        name: 'ai_usage',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Usage', link: { name: 'ai_usage' } },
        },
      },
    ],
  },
  {
    path: '/insights',
    redirect: '/insights/search',
    name: 'insights',
    component: PassThrough,
    meta: {
      breadcrumb: { display: 'Insights', link: { name: 'insights' } },
    },
    children: [
      {
        path: '/insights/search',
        component: () => import('@/pages/Clustering/Search.vue'),
        name: 'similarity_search',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Similarity Search', link: { name: 'similarity_search' } },
        },
      },
      {
        path: '/insights/statistics',
        component: () => import('@/pages/Clustering/Statistics.vue'),
        name: 'statistics',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Statistics', link: { name: 'statistics' } },
        },
      },
    ],
  },
  {
    path: '/feedback',
    redirect: '/feedback/improve',
    name: 'feedback',
    component: PassThrough,
    meta: {
      breadcrumb: { display: 'Feedback', link: { name: 'feedback' } },
    },
    children: [
      {
        path: '/feedback/improve',
        component: () => import('@/pages/Feedback/ImproveAI.vue'),
        name: 'give_feedback',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Improve AI', link: { name: 'give_feedback' } },
        },
      },
      {
        path: '/feedback/display',
        component: () => import('@/pages/Feedback/MyFeedback.vue'),
        name: 'display_feedback',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'My Feedback', link: { name: 'display_feedback' } },
        },
      },
      {
        path: '/feedback/queues-config',
        component: () => import('@/pages/Feedback/ConfigureQueues.vue'),
        name: 'queues_config',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Configure Queues', link: { name: 'queues_config' } },
        },
      },
      {
        path: '/feedback/anomalies',
        component: () => import('@/pages/Feedback/Anomalies.vue'),
        name: 'find_anomaly',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Anomalies', link: { name: 'find_anomaly' } },
        },
      },
    ],
  },
  {
    path: '/models',
    redirect: '/models/manage',
    name: 'models',
    component: PassThrough,
    meta: {
      breadcrumb: { display: 'Models', link: { name: 'models' } },
    },
    children: [
      {
        path: '/models/configure',
        component: () => import('@/pages/Models/ModelsConfigure.vue'),
        name: 'configure',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Configure', link: { name: 'configure' } },
        },
      },
      {
        path: '/models/training',
        component: () => import('@/pages/Models/ModelsTraining.vue'),
        name: 'training',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Training', link: { name: 'training' } },
        },
      },
      {
        path: '/models/stage',
        component: () => import('@/pages/Models/ModelsStage.vue'),
        name: 'stage',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Stage', link: { name: 'stage' } },
        },
      },
    ],
  },

  {
    path: '/custom-rules',
    redirect: '/custom-rules/manage',
    name: 'custom-rules',
    component: PassThrough,
    meta: {
      breadcrumb: { display: 'Custom Rules', link: { name: 'custom-rules' } },
    },
    children: [
      {
        path: '/custom-rules/manage',
        component: () => import('@/pages/CustomRules/CustomRulesManage.vue'),
        name: 'ruleHome',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Manage', link: { name: 'ruleHome' } },
        },
      },
      {
        path: '/custom-rules/manage/:ruleId',
        component: () => import('@/pages/CustomRules/RuleCollection.vue'),
        name: 'ruleEdit',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
      {
        path: '/custom-rules/stage',
        component: () => import('@/pages/CustomRules/CustomRulesStage.vue'),
        name: 'ruleStage',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Stage', link: { name: 'ruleStage' } },
        },
      },
    ],
  },
  {
    path: '/routing',
    redirect: '/routing/logs',
    component: PassThrough,
    name: 'routing',
    beforeEnter: defaultCheckPermission,
    meta: {
      breadcrumb: { display: 'Routing', link: { name: 'routing' } },
    },
    children: [
      {
        path: '/routing/logs',
        component: () => import('@/pages/Routing/RoutingLogs.vue'),
        name: 'routingLogs',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Logs', link: { name: 'routingLogs' } },
        },
      },
      {
        path: '/routing/manage',
        component: () => import('@/pages/Routing/RoutingManage.vue'),
        name: 'routingManage',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Manage', link: { name: 'routingManage' } },
        },
      },
      {
        path: '/routing/stage',
        component: () => import('@/pages/Routing/RoutingStage.vue'),
        name: 'routingStage',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Stage', link: { name: 'routingStage' } },
        },
      },

    ],
  },
  {
    path: '/configuration',
    redirect: '/configuration/anonymization',
    name: 'configuration',
    component: PassThrough,
    meta: {
      breadcrumb: { display: 'Configuration', link: { name: 'configuration' } },
    },
    children: [
      {
        path: '/configuration/anonymization',
        component: () => import('@/pages/Configuration/Anonymization.vue'),
        name: 'anonymization',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Anonymization', link: { name: 'anonymization' } },
        },
      },
      {
        path: '/configuration/connection_config',
        component: () => import('@/pages/Configuration/ConnectionConfig.vue'),
        name: 'connection_config',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Connection Config', link: { name: 'connection_config' } },
        },
      },
      {
        path: '/configuration/data_sync',
        component: () => import('@/pages/Configuration/DataSync.vue'),
        name: 'data_sync',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Data Sync', link: { name: 'data_sync' } },
        },
      },
      {
        path: '/configuration/data_purge',
        component: () => import('@/pages/Configuration/DataPurge.vue'),
        name: 'data_purge',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Data Purge', link: { name: 'data_purge' } },
        },
      },
      {
        path: '/configuration/signature_stripping',
        component: () => import('@/pages/Configuration/SignatureStripping.vue'),
        name: 'signature_stripping',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Signature Stripping', link: { name: 'signature_stripping' } },
        },
      },
      {
        path: '/documentation/api',
        component: () => import('@/pages/Configuration/Documentation.vue'),
        name: 'api_docs',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Documentation', link: { name: 'api_docs' } },
        },
      },
      {
        path: '/user-management',
        component: () => import('@/pages/Configuration/UserManagement.vue'),
        name: 'user_management',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'User Management', link: { name: 'user_management' } },
        },
      },
      {
        path: '/configuration/task_status',
        component: () => import('@/pages/Configuration/TaskStatus.vue'),
        name: 'task_status',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Task Status', link: { name: 'task_status' } },
        },
      },
      {
        path: '/configuration/single_sign_on',
        component: () => import('@/pages/Configuration/SingleSignOnWrapper.vue'),
        name: 'single_sign_on',
        beforeEnter: defaultCheckPermission,
        meta: {
          breadcrumb: { display: 'Single Sign On', link: { name: 'single_sign_on' } },
        },
      },
    ],
  },
  // At the end we have a catch all. Since routes are matched in order this will only match if
  // no other route matches.
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/PageNotFoundPage.vue'),
    name: '404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('sidebar/setLoading', true);
  next();
});

router.afterEach(() => {
  store.commit('sidebar/setLoading', false);
});
export default router;
