import { endpoints } from '@/js/endpoints';

const accessState = {
  // id, pretty_name, is_active, is_aad_user, role
  users: [],
  // id, unid, name, verified
  itsmUsers: [],
  itsmUserSearchResult: [],
  searching: false,
};

const getters = {
  users: (state) => state.users,
  activeUsers: (state) => state.users.filter((x) => x.is_active),
  itsmUsers: (state) => state.itsmUsers,
  itsmUserSearchResult: (state) => state.itsmUserSearchResult,
  searching: (state) => state.searching,
};

const mutations = {
  setUsers(state, usersList) {
    state.users = usersList;
  },
  setItsmUsers(state, usersList) {
    state.itsmUsers = usersList;
  },
  setItsmUserSearchResult(state, searchResult) {
    state.itsmUserSearchResult = searchResult;
  },
  setSearching(state, value) {
    state.searching = value;
  },
};

const actions = {
  async refreshUsers({ commit, dispatch }) {
    const { results } = await dispatch(
      'auth/callBackendSuccess',
      { url: `${endpoints.usersManagement}` },
      { root: true },
    );
    commit('setUsers', results);
  },
  async ensureUsers({ commit, dispatch, state }) {
    // Check if we already have the data we need.
    if ([
      state.users.length > 0,
      state.itsmUsers.length > 0,
    ].every((wasFound) => (wasFound))) return;
    // If not, let's get it!
    await dispatch('refreshUsers');
    const { results } = await dispatch(
      'auth/callBackendSuccess',
      { url: `${endpoints.itsmUsers}` },
      { root: true },
    );
    commit('setItsmUsers', results);
  },
  async searchItsmUsers({ commit, dispatch }, { query }) {
    if (typeof query === 'string' || query instanceof String) {
      commit('setSearching', true);
      const { results } = await dispatch(
        'auth/callBackendSuccess',
        {
          url: `${endpoints.itsmUsers}`,
          params: {
            search_query: query,
          },
        },
        { root: true },
      );
      commit('setItsmUserSearchResult', results);
      commit('setSearching', false);
    }
  },
};

export default {
  namespaced: true,
  state: accessState,
  getters,
  mutations,
  actions,
};
