import { sortBy } from 'lodash';

import { endpoints } from '@/js/endpoints';

const anonymizationState = {
  anonymizers: [],
  fields: [],
};

const getters = {
  anonymizers: (state) => sortBy(state.anonymizers, [(a) => a.name]),
  fields: (state) => sortBy(state.fields, [(a) => a.field.display]),
};

const mutations = {
  setAnonymizers(state, anonymizers) {
    state.anonymizers = anonymizers;
    return anonymizers;
  },
  addAnonymizer(state, anonymizer) {
    state.anonymizers.push(anonymizer);
    return anonymizer;
  },
  updateAnonymizer(state, anonymizer) {
    const index = state.anonymizers.findIndex((a) => a.unid === anonymizer.unid);
    if (index !== -1) {
      state.anonymizers.splice(index, 1, anonymizer);
    }
    return anonymizer;
  },
  deleteAnonymizer(state, { unid }) {
    const index = state.anonymizers.findIndex((a) => a.unid === unid);
    if (index !== -1) {
      state.anonymizers.splice(index, 1);
    }
    return state.anonymizers;
  },
  setFields(state, fields) {
    state.fields = fields;
    return state.fields;
  },
  updateField(state, { fieldUnid, anonymizers }) {
    state.fields.find((f) => f.unid === fieldUnid).anonymizers = anonymizers;
    return state.fields;
  },
};

const actions = {
  async prepare({ commit, dispatch, state }, { type, refresh }) {
    let url = endpoints.anonAnonymizers;
    let mutation = 'setAnonymizers';
    if (type === 'fields') {
      url = endpoints.anonAnonymizedFields;
      mutation = 'setFields';
    }

    if (!state[type].length || refresh) {
      const { results } = await dispatch(
        'auth/callBackendSuccess',
        { url },
        { root: true },
      );
      return commit(mutation, results);
    }
    return state[type];
  },
  async updateAnonymizer({ commit, dispatch }, anonymizer) {
    const url = `${endpoints.anonAnonymizers}${anonymizer.unid}/`;
    let response;
    if (anonymizer.type === 2) {
      response = await dispatch(
        'auth/callBackendSuccess',
        {
          url,
          method: 'PATCH',
          payload: {
            replacement: anonymizer.replacement,
          },
        },
        { root: true },
      );
    } else {
      const {
        name, description, pattern, replacement,
      } = anonymizer;
      response = await dispatch(
        'auth/callBackendSuccess',
        {
          url,
          method: 'PUT',
          payload: {
            name, description, pattern, replacement,
          },
        },
        { root: true },
      );
    }
    return commit('updateAnonymizer', response);
  },
  async addAnonymizer({ commit, dispatch }, anonymizer) {
    const {
      name, description, pattern, replacement,
    } = anonymizer;
    const response = await dispatch(
      'auth/callBackendSuccess',
      {
        url: endpoints.anonAnonymizers,
        method: 'POST',
        payload: {
          name, description, pattern, replacement,
        },
      },
      { root: true },
    );
    return commit('addAnonymizer', response);
  },
  async deleteAnonymizer({ commit, dispatch }, { unid }) {
    await dispatch(
      'auth/callBackendSuccess',
      {
        url: `${endpoints.anonAnonymizers}${unid}/`,
        method: 'DELETE',
      },
      { root: true },
    );
    return commit('deleteAnonymizer', { unid });
  },
  async updateField({ commit, dispatch }, { fieldUnid, anonymizers }) {
    await dispatch(
      'auth/callBackendSuccess',
      {
        url: `${endpoints.anonAnonymizedFields}${fieldUnid}/`,
        method: 'PATCH',
        payload: { anonymizers },
      },
      { root: true },
    );
    return commit('updateField', { fieldUnid, anonymizers });
  },
};

export default {
  namespaced: true,
  state: anonymizationState,
  getters,
  mutations,
  actions,
};
