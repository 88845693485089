<template>
  <div>
    <slot v-if="hasPermission" />
    <PvButton
      v-else-if="showInstead === 'button'"
      disabled
      label="Access Denied"
      severity="secondary"
    />
    <PvInputText
      v-else-if="showInstead === 'input'"
      readonly
      model-value="Access Denied"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PermissionGuard',
  props: {
    permReq: {
      type: String,
      required: true,
    },
    permNameOverride: {
      type: String,
      default: '',
    },
    showInstead: {
      type: String,
      default: 'hidden',
    },
  },
  computed: {
    ...mapGetters('auth', ['currentRoutePermission']),
    permissionName() {
      return this.permNameOverride ? this.permNameOverride : this.$route.name;
    },
    myPermission() {
      return this.currentRoutePermission(this.permissionName);
    },
    hasPermission() {
      const levels = { no: 0, read: 1, full: 2 };
      return levels[this.myPermission] >= levels[this.permReq];
    },

  },
};
</script>
