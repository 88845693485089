import buildinfo from '@/assets/buildinfo.json';
import spec from '../../package.json';

const name = spec.name;
const version = spec.version;

export function versionInfo() {
  return {
    name,
    version,
    ...buildinfo,
  };
}
export function versionInfoArray() {
  const info = versionInfo();
  const ret = [];
  for (const k of Object.keys(info)) {
    ret.push({ name: k, value: info[k] });
  }
  return ret;
}

export function addObjects(existing, addition) {
  return Object.keys(addition).reduce((soFar, key) => {
    const toAdd = addition[key];
    if (typeof toAdd === 'number') {
      // eslint-disable-next-line no-param-reassign
      soFar[key] = (soFar[key] || 0) + addition[key];
    } else if (typeof toAdd === 'object') {
      // eslint-disable-next-line no-param-reassign
      soFar[key] = addObjects(soFar[key] || {}, toAdd);
    }
    return soFar;
  }, existing);
}

export function exportToCsv(filename, rows) {
  // eslint-disable-next-line func-names
  const processRow = function (row) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// SNAKE-CAMEL-CONVERSION
export function strToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

export function objToCamel(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    // for some reason js considers null and [] an object, so additional check is required
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      return [strToCamel(k), objToCamel(v)];
    }
    return [strToCamel(k), v];
  }));
}

export function strToSnake(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function objToSnake(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    // for some reason js considers null and [] an object, so additional check is required
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      return [strToSnake(k), objToSnake(v)];
    }
    return [strToSnake(k), v];
  }));
}

export function camelStrToText(str) {
  return str.split('_').map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(' ');
}
export function camelCaseToText(str) {
  return str.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
}

// vuex templates init function
export function getModuleProps(moduleSpecific, templates) {
  return {
    state: {
      ...moduleSpecific.state,
      ...Object.assign({}, ...templates.map((e) => e.state)),
    },
    getters: {
      ...moduleSpecific.getters,
      ...Object.assign({}, ...templates.map((e) => e.getters)),
    },
    mutations: {
      ...moduleSpecific.mutations,
      ...Object.assign({}, ...templates.map((e) => e.mutations)),
    },
    actions: {
      ...moduleSpecific.actions,
      ...Object.assign({}, ...templates.map((e) => e.actions)),
    },
  };
}

// Date formating
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const moment = require('moment-timezone');

moment.tz.setDefault('UTC');

export function dateFormatter(value, format = dateFormat) {
  return moment(value).format(format);
}
