import { jwtDecode } from 'jwt-decode';
import { endpoints } from '@/js/endpoints';
import { objectMap } from '@/js/misc';
import { BACKEND_AXIOS } from '@/js/httpRequest';
// eslint-disable-next-line import/no-cycle
import router from '../router';

const authState = {
  expired: false,
  jwt: localStorage.getItem('token'),
  refresh: localStorage.getItem('refresh'),
  loggedIn: false,
  permissions: {},
  accessLevel: null,
  lastInspect: null,
  lastBackendCall: null,
  topMessage: null,
};

const authGetters = {
  displayName: (state, getters, rootState, rootGetters) => rootGetters.username,
  loggedIn(state) {
    return state.loggedIn;
  },
  topMessage(state) {
    return state.topMessage;
  },
  featurePermissions(state) {
    const accessLevel = objectMap(state.permissions, (value) => value);
    return {
      // Change these to follow state.permissions when rolling out
      home: 'full',
      manage: accessLevel.moma_admin,
      configure: accessLevel.moma_admin,
      stage: accessLevel.moma_admin,
      training: accessLevel.moma_admin,
      ruleHome: accessLevel.custom_rules_admin,
      ruleEdit: accessLevel.custom_rules_admin,
      ruleStage: accessLevel.custom_rules_admin,
      give_feedback: accessLevel.feedback_user,
      display_feedback: accessLevel.feedback_user,
      queues_config: accessLevel.feedback_admin,
      ai_test_site: accessLevel.default_swaps_view_grp,
      ai_usage: accessLevel.swaps_admin_view_grp,
      ai_configure: accessLevel.swaps_admin_view_grp,
      anonymization: accessLevel.data_sync,
      connection_config: accessLevel.data_sync,
      data_sync: accessLevel.data_sync,
      data_purge: accessLevel.data_sync,
      stats_traffic: accessLevel.stats_view_grp,
      stats_feedback: accessLevel.stats_view_grp,
      stats_rules: accessLevel.stats_view_grp,
      stats_performance: accessLevel.stats_view_grp,
      find_anomaly: accessLevel.anomaly_detection_admin,
      similarity_search: accessLevel.similarity_search,
      user_management: accessLevel.access_view,
      task_status: accessLevel.moma_admin,
      single_sign_on: accessLevel.access_view,
      routingStage: accessLevel.routing_queue_admin,
      routingLogs: accessLevel.routing_queue_viewer,
      routingManage: accessLevel.routing_queue_viewer,
    };
  },
  currentRoutePermission: (state, getters) => (routeName) => {
    const permissions = getters.featurePermissions;
    return permissions[routeName];
  },
  lastInspectAndCall(state) {
    return {
      inspect: state.lastInspect,
      call: state.lastBackendCall,
    };
  },
  headerAuthorization: (state) => ({
    headers: {
      Authorization: `Bearer ${state.jwt}`,
    },
  }),
};

const mutations = {
  setTopMessage(state, newMessage = null) {
    state.topMessage = newMessage;
  },
  removeToken(state) {
    localStorage.removeItem('token');
    state.jwt = null;
  },
  removeRefresh(state) {
    localStorage.removeItem('refresh');
    state.refresh = null;
  },
  updateToken(state, newToken) {
    localStorage.setItem('token', newToken);
    state.jwt = newToken;
  },
  updateRefresh(state, newToken) {
    localStorage.setItem('refresh', newToken);
    state.refresh = newToken;
  },
  updateExpired(state, payload) {
    state.expired = payload;
  },
  updateLoggedIn(state, payload) {
    state.loggedIn = payload;
  },
  updatePermissions(state, payload) {
    state.permissions = payload;
  },
  setLastInspect(state) {
    state.lastInspect = Date.now();
  },
  setLastBackendCall(state) {
    state.lastBackendCall = Date.now();
  },
};

const actions = {
  login(_, authData) {
    return BACKEND_AXIOS.post(endpoints.obtainJWT, {
      username: authData.username,
      password: authData.password,
    });
  },

  logout({ commit, state, getters }) {
    if (state.jwt) {
      BACKEND_AXIOS.get(endpoints.logoutJWT, getters.headerAuthorization);
    }
    commit('updateLoggedIn', false);
    commit('sidebar/setShowSidebar', false, { root: true });
    commit('updatePermissions', {});
    commit('removeToken');
    commit('removeRefresh');
  },

  verifyToken({ commit, state }) {
    return BACKEND_AXIOS.post(endpoints.verifyJWT, {
      token: state.jwt,
    }).catch((error) => {
      const status = error.response.status;
      if (status === 401) {
        // token completely expired and re-login is required
        commit('updateExpired', true);
        router.push({ name: 'login' });
      }
      throw error;
    });
  },

  async refreshToken({ commit, state }) {
    return BACKEND_AXIOS.post(endpoints.refreshJWT, {
      refresh: state.refresh,
    })
      .then((res) => {
        commit('updateToken', res.data.access);
      })
      .catch((error) => {
        // TODO: This should be handled.
        throw error;
      });
  },

  async inspectToken({ commit, dispatch, state }) {
    if (state.lastInspect !== null && (Date.now() - state.lastInspect) < 5000) {
      // console.log('Last expect less than 5 secs ago!');
      return;
    }
    // Set both timestamps, first is used to know last verify, other
    // is last time this function was called
    commit('setLastInspect');
    const token = state.jwt;
    const refresh = state.refresh;
    if (token !== null && refresh !== null) {
      const accessDecoded = jwtDecode(token);
      const refreshDecoded = jwtDecode(refresh);
      const accessExp = accessDecoded.exp;
      const accessTimeLeft = accessExp - (Date.now() / 1000);
      const refreshTimeLeft = refreshDecoded.exp - (Date.now() / 1000);

      const maxTimeToRefreshAccessToken = 5;
      // console.log(accessTimeLeft)
      if (accessTimeLeft >= 600) {
        // More than 10 minutes left, no need to do stuff
      } else if (accessTimeLeft < 600
        && refreshTimeLeft > maxTimeToRefreshAccessToken) {
        await dispatch('refreshToken');
      } else {
        // token completely expired and re-login is required
        commit('updateExpired', true);
      }
    }
  },

  // Returns promise
  callBackend({ getters }, {
    url, method = 'GET', payload = {}, params = {},
  }) {
    const config = {
      ...getters.headerAuthorization,
      params,
    };
    switch (method) {
      case 'POST':
        return BACKEND_AXIOS.post(url, payload, config);
      case 'PUT':
        return BACKEND_AXIOS.put(url, payload, config);
      case 'PATCH':
        return BACKEND_AXIOS.patch(url, payload, config);
      case 'DELETE':
        return BACKEND_AXIOS.delete(url, config);
      default:
        return BACKEND_AXIOS.get(url, config);
    }
  },

  async callBackendSuccess({ dispatch, commit }, {
    url, method = 'GET', payload = {}, params = {}, handleRespError = true,
  }) {
    commit('setLastBackendCall');
    return dispatch('callBackend', {
      url, method, payload, params,
    }).then(
      (response) => {
        const status = response.status;
        if (status >= 200 && status < 300) {
          return response.data;
        }
        const msg = `Got status ${status} on ${url}`;
        throw new Error(msg);
      },
      (error) => {
        if (error.response) {
          /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
          if (handleRespError) {
            const status = error.response.status;
            if (status === 401) {
              // Unauthorised (user should log-in again)
              commit('setTopMessage', 'Your session has expired due to inactivity');
              setTimeout(() => {
                router.push({ path: '/' });
              }, 5000);
            } else if (status === 403) {
              // Forbidden (user is not allowed to call API)
              commit('setTopMessage', 'Your account does not have the right permissions');
              setTimeout(() => {
                router.push({ path: '/' });
              }, 5000);
            } else if (status === 429) {
              // Too many requests (task handler is busy)
              commit('setTopMessage', 'Server is busy - please check availability later');
            } else {
              // Can be anything from 404 to whatever, better not do anything
              // general right now
              // commit('setTopMessage', "Your session has expired due to inactivity");
              // setTimeout(function () {
              //   router.push({ path: '/' });
              // }, 5000);
            }
          }
        } else if (error.request) {
          /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
          // Backend server might be down
          // console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
        }
        throw (error);
      },
    );
  },

  getPermissions({ commit, dispatch }) {
    return dispatch('callBackendSuccess', { url: endpoints.getPermissions }).then(
      (data) => {
        if (data !== undefined) {
          commit('updatePermissions', data.by_view || {});
        }
      },
    );
  },
};

export default {
  namespaced: true,
  state: authState,
  getters: authGetters,
  mutations,
  actions,
};
