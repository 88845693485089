import moment from 'moment';
import { endpoints } from '@/js/endpoints';
// eslint-disable-next-line import/no-cycle
import { collectPaginatedContent } from '@/js/apiHandlers';

const tabOptions = [
  {
    key: 'trainedModel',
    display: 'Trained Model',
    funcName: 'train_model',
    baseUrl: endpoints.trainedModel,
    fields: [
      'id', 'train_dataset',
      {
        key: 'create_time',
        formatter: (value) => moment(String(value)).format('YYYY-MM-DD HH:mm'),
      }, 'task_id', {
        key: 'ok_for_use',
        formatter: (value) => (value ? 'Yes' : 'No'),
      },
    ],
  },
];

const modelsState = {
  // MoMa Module selection
  moduleSelected: null,

  // Details area
  activeTaskId: null,
  isActiveTaskFinished: false,

  // Dataset, TrainedModel options for Forms
  entryOptions: new Array(tabOptions.length).fill(null),

  // Swap unit and their configs
  swapUnits: null,

  // WorkSpaces and MoMaModules
  workSpaces: [],
  selectedWorkSpace: null,
  momaModules: [],
  selectedMomaModule: null,
  // inputFields: [],
  preprocessingConfig: null,
  pretrainedModels: [],
};

const modelsGetters = {
  moduleSelected: (state) => state.moduleSelected,
  activeTaskId: (state) => state.activeTaskId,
  isActiveTaskFinished: (state) => state.isActiveTaskFinished,
  tabOptions: () => tabOptions,
  entryOptions: (state) => (key) => {
    const ind = tabOptions.findIndex((e) => e.key === key);
    return state.entryOptions[ind];
  },
  workSpaces: (state) => state.workSpaces.filter((x) => !x.hidden),
  selectedWorkSpace: (state) => state.selectedWorkSpace,
  selectedWorkSpaceData: (state) => state.workSpaces.find((x) => x.id === state.selectedWorkSpace),
  momaModules: (state) => state.momaModules,
  selectedMomaModule: (state) => state.selectedMomaModule,
  preprocessingHeaders: (state) => state.preprocessingConfig.header_starts,
  preprocessingSignatures: (state) => state.preprocessingConfig.signature_starts,
  pretrainedModels: (state) => state.pretrainedModels,
};

const mutations = {
  setModule(state, { unid }) {
    state.moduleSelected = unid;
  },
  setActiveTaskFinishedStatus(state, { status }) {
    state.isActiveTaskFinished = status === true;
  },
  setEntryOptions(state, { key, opts }) {
    const ind = tabOptions.findIndex((e) => e.key === key);
    state.entryOptions.splice(ind, 1, opts);
  },

  setWorkSpaces(state, { workSpaces }) {
    state.workSpaces = workSpaces;
  },
  setSelectedWorkSpace(state, { workSpaceId }) {
    state.selectedWorkSpace = workSpaceId;
  },
  setMomaModules(state, { momaModules }) {
    state.momaModules = momaModules;
  },
  // setInputFields(state, { inputFields }) {
  //   state.inputFields = inputFields;
  // },
  setSelectedMomaModule(state, { momaModuleId }) {
    state.selectedMomaModule = momaModuleId;
  },

  setPreprocessingConfig: (state, config) => {
    state.preprocessingConfig = config;
  },
  setPretrainedModels(state, { pretrainedModels }) {
    state.pretrainedModels = pretrainedModels;
  },
};

const actions = {
  async refreshEntryOptions({ commit }, { key }) {
    const tab = tabOptions.find((e) => e.key === key);
    await collectPaginatedContent(tab.baseUrl, 0, null, null, { page_size: 500 }).then(
      (result) => {
        commit('setEntryOptions', {
          key,
          opts: result.map((e) => ({
            text: e.id,
            value: e.id,
            momaModule: e.moma_module,
            okForUse: e.ok_for_use,
          })),
        });
      },
    );
  },

  async refreshWorkSpaces({ commit }) {
    await collectPaginatedContent(endpoints.workSpace, 0, null).then((result) => {
      commit('setWorkSpaces', {
        workSpaces: result,
      });
    });
  },
  async refreshPretrainedModels({ dispatch, commit }) {
    await dispatch('auth/callBackendSuccess', {
      url: `${endpoints.pretrainedModels}`, method: 'GET',
    }, { root: true }).then(
      ((data) => {
        commit('setPretrainedModels', {
          pretrainedModels: data,
        });
      }),
    );
  },
  async createWorkSpace({ dispatch, commit }, { name }) {
    // Remember to do uniqueness check on name against state.workSpaces
    if (name.length <= 90) {
      await dispatch('auth/callBackendSuccess', {
        url: endpoints.workSpace,
        method: 'POST',
        payload: {
          name,
          filter_tags: [],
          exclude_tags: [],
        },
      }, { root: true }).then((data) => {
        dispatch('refreshWorkSpaces');
        commit('setSelectedWorkSpace', { workSpaceId: data.id });
      });
    }
  },
  async updateWorkSpace({ dispatch, getters }, {
    workSpaceId, filterTags, excludeTags,
  }) {
    // Check conditions from SimpleFieldConfigSerializer and field_config_can_be_added
    if (getters.workSpaces.map((x) => x.id).includes(workSpaceId)) {
      await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.workSpace}${workSpaceId}/`,
        method: 'PATCH',
        payload: {
          filter_tags: filterTags,
          exclude_tags: excludeTags,
        },
      }, { root: true }).then(() => {
        dispatch('refreshWorkSpaces');
      });
    }
  },
  async upsertFieldConfig({ dispatch, getters }, {
    workSpaceId, fields,
  }) {
    // Check conditions from SimpleFieldConfigSerializer and field_config_can_be_added
    if (getters.workSpaces.map((x) => x.id).includes(workSpaceId)) {
      await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.workSpace}${workSpaceId}/`,
        method: 'PATCH',
        payload: {
          fields,
        },
      }, { root: true }).then(() => {
        dispatch('refreshWorkSpaces');
      });
    }
  },
  async deleteWorkSpace({ dispatch, getters }, { workSpaceId }) {
    if (getters.workSpaces.map((x) => x.id).includes(workSpaceId)) {
      await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.workSpace}${workSpaceId}/`, method: 'DELETE',
      }, { root: true }).then(() => {
        dispatch('refreshWorkSpaces');
      });
    }
  },

  async refreshMomaModules({ commit }) {
    await collectPaginatedContent(endpoints.module, 0, null).then((result) => {
      commit('setMomaModules', {
        momaModules: result.map((x) => {
          // eslint-disable-next-line no-param-reassign
          x.audit_trailing_blacklist = x.audit_trailing_blacklist.map((i) => i.toString());
          // eslint-disable-next-line no-param-reassign
          x.audit_trailing_whitelist = x.audit_trailing_whitelist.map((i) => i.toString());
          return x;
        }),
      });
    });
  },
  async createMomaModule({ dispatch }, { payload }) {
    // Remember to do uniqueness check on name against state.momaModules
    const data = await dispatch('auth/callBackendSuccess', {
      url: endpoints.module,
      method: 'POST',
      payload,
    }, { root: true });
    dispatch('refreshMomaModules');
    return data;
  },
  async updateMomaModule({ dispatch, getters }, { momaModuleId, payload }) {
    if (getters.momaModules.map((x) => x.id).includes(momaModuleId)) {
      await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.module}${momaModuleId}/`,
        method: 'PATCH',
        payload,
      }, { root: true }).then(() => {
        dispatch('refreshMomaModules');
      });
    }
  },
  async deleteMomaModule({ dispatch, getters }, { momaModuleId }) {
    if (getters.momaModules.map((x) => x.id).includes(momaModuleId)) {
      await dispatch('auth/callBackendSuccess', {
        url: `${endpoints.module}${momaModuleId}/`, method: 'DELETE',
      }, { root: true }).then(() => {
        dispatch('refreshMomaModules');
      });
    }
  },

  async getPreprocessingConfig({ dispatch, commit }) {
    await dispatch('auth/callBackendSuccess', {
      url: endpoints.preprocessingConfig, method: 'GET',
    }, { root: true }).then((data) => {
      // This is a singleton model
      commit('setPreprocessingConfig', data.results[0]);
    });
  },

  async editPreprocessingConfig({ dispatch, commit }, { payload }) {
    await dispatch('auth/callBackendSuccess', {
      url: `${endpoints.preprocessingConfig}1`, method: 'PATCH', payload,
    }, { root: true }).then((data) => {
      commit('setPreprocessingConfig', data);
    });
  },

  async testSignatureStripping({ dispatch }, { payload }) {
    const resp = await dispatch('auth/callBackendSuccess', {
      url: `${endpoints.preprocessingConfig}test-stripping/`, method: 'POST', payload,
    }, { root: true });
    return resp;
  },

};

export default {
  namespaced: true,
  state: modelsState,
  getters: modelsGetters,
  mutations,
  actions,
};
