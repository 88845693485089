<template>
  <div :class="[proppedClasses, 'bg-white p-3 shadow-sm']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FloatingContainer',
  props: {
    borderRadius: {
      type: String,
      default: '',
    },
  },
  computed: {
    proppedClasses() {
      let classes = '';
      switch (this.borderRadius) {
        case '50':
          classes += 'rounded-lg ';
          break;
        case '25':
          classes += 'rounded ';
          break;
        default:
          classes += 'rounded-xl ';
          break;
      }
      return classes;
    },
  },
};
</script>
