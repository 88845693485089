// returns a new object with the values at each key mapped using mapFn(value)
const moment = require('moment-timezone');

moment.tz.setDefault('UTC');

export function objectMap(object, mapFn) {
  return Object.keys(object).reduce((result, key) => {
    const newResult = result;
    newResult[key] = mapFn(object[key]);
    return newResult;
  }, {});
}

export function capitalizeFirstLetter(string) {
  if (typeof string === 'string') {
    if (string === '') {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  throw new Error('Input is not a string');
}

export function prettyDate(string) {
  if (string !== undefined) {
    return moment(String(string)).format('YYYY-MM-DD');
  }
  return '';
}

export function prettyDateTime(string) {
  return string ? moment(String(string)).format('YYYY-MM-DD HH:mm') : '';
}

export function isValidOption(value, list, valueKey = 'value') {
  if (list.length) {
    const valids = list.map((option) => String(option[valueKey]));
    return valids.indexOf(String(value)) !== -1;
  }
  return false;
}

export function prepareRoutingSessionData(sessionData) {
  const sessionCopy = JSON.parse(JSON.stringify(sessionData));
  const initialState = JSON.parse(sessionCopy.initial_state);
  /* eslint-disable dot-notation */
  sessionCopy.initial_ticket = initialState['_ticket'];
  sessionCopy.raw_content = initialState['content'];
  sessionCopy.logs = sessionCopy.logs.sort(
    /* eslint-disable no-confusing-arrow, no-nested-ternary */
    (a, b) => (a.created_date > b.created_date) ? 1 : (
      (b.created_date > a.created_date) ? -1 : 0
    ),
  );
  return sessionCopy;
}

export function handleRuleIds(ruleIdsString) {
  if (typeof ruleIdsString !== 'string') {
    return null;
  }
  return ruleIdsString.split(';').map(
    (x) => parseInt(x, 10),
  ).filter((x) => Number.isInteger(x)).filter((x) => x > 0);
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
